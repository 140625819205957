import React from 'react';
import PropTypes from 'prop-types';

const FormatToolbar = (props) => {
  const { children } = props;

  return (<div className="format-toolbar">{children}</div>);
};

FormatToolbar.propTypes = { children: PropTypes.node.isRequired };

export default FormatToolbar;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

function createSelectItem(selectElementKey, selectElementValue, selected, category,
  extraItemsCategory, setColumnFilters) {
  return (
    <DropdownItem
      key={selectElementKey}
      active={selectElementValue === selected}
      onClick={() => {
        const columnFilters = {};
        columnFilters[category] = null;
        columnFilters[extraItemsCategory] = null;
        setColumnFilters(columnFilters);
      }}
    >
      {selectElementValue}
    </DropdownItem>
  );
}

function createRegionItem(key, item, selected, index, extraItemsCategory, category,
  setMyRegionFilter, setColumnFilters) {
  return (
    <DropdownItem
      key={key}
      active={item === selected}
      onClick={() => {
        if (index === 1) {
          setMyRegionFilter(category, extraItemsCategory);
        } else {
          const columnFilters = {};
          columnFilters[category] = null;
          columnFilters[extraItemsCategory] = item;
          setColumnFilters(columnFilters);
        }
      }}
    >
      {item}
    </DropdownItem>
  );
}

function createCountryItem(key, item, selected, category, setColumnFilters, extraItemsCategory) {
  return (
    <DropdownItem
      key={key}
      active={item === selected}
      onClick={() => {
        const columnFilters = {};
        columnFilters[category] = key;
        columnFilters[extraItemsCategory] = null;
        setColumnFilters(columnFilters);
      }}
    >
      {item}
    </DropdownItem>
  );
}

function createCountryRegionFilter(dropdownOpen, toggle, caretDirection, selected, right,
  category, items, itemsHeader, extraItemsCategory, extraItems, extraItemsHeader,
  setColumnFilter, setColumnFilters, setMyRegionFilter) {
  const selectElement = Object.keys(extraItems);
  const selectElementKey = selectElement[0];
  const selectElementValue = extraItems[selectElementKey];

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={caretDirection}>
      <DropdownToggle caret>{selected}</DropdownToggle>
      <DropdownMenu className="Dropdown-scroll" right={right}>
        { selectElement.length > 0
          ? createSelectItem(selectElementKey, selectElementValue, selected, category,
            extraItemsCategory, setColumnFilters)
          : null
    }
        { Object.entries(extraItems).map(([key, item], index) => (
          (index === 0)
            ? <DropdownItem header key={extraItemsHeader}>{extraItemsHeader}</DropdownItem>
            : createRegionItem(key, item, selected, index, extraItemsCategory, category,
              setMyRegionFilter, setColumnFilters)
        ))}
        <DropdownItem header key={itemsHeader}>{itemsHeader}</DropdownItem>
        { Object.entries(items).map(([key, item]) => (
          createCountryItem(key, item, selected, category, setColumnFilters, extraItemsCategory)
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

const DropDown = (props) => {
  const {
    category, items, selected, caretDirection, setColumnFilter, right,
    extraItemsCategory, itemsHeader, extraItems, extraItemsHeader, setColumnFilters,
    setMyRegionFilter,
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  let dropdown;
  if (extraItems) {
    dropdown = createCountryRegionFilter(dropdownOpen, toggle, caretDirection, selected, right,
      category, items, itemsHeader, extraItemsCategory, extraItems, extraItemsHeader,
      setColumnFilter, setColumnFilters, setMyRegionFilter);
  } else {
    dropdown = (
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={caretDirection}>
        <DropdownToggle caret>{selected}</DropdownToggle>
        <DropdownMenu className="Dropdown-scroll" right={right}>
          { Object.entries(items).map(([key, item], index) => (
            <DropdownItem
              key={key}
              active={item === selected}
              onClick={() => setColumnFilter(category, (index === 0 && category !== 'count') ? null : key)}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }

  return (
    dropdown
  );
};

DropDown.defaultProps = {
  category: null,
  items: null,
  selected: null,
  caretDirection: null,
  setColumnFilter: null,
  right: false,
  itemsHeader: null,
  extraItemsCategory: null,
  extraItems: null,
  extraItemsHeader: null,
  setColumnFilters: null,
  setMyRegionFilter: null,
};

DropDown.propTypes = {
  category: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.object,
  selected: PropTypes.string,
  caretDirection: PropTypes.string,
  setColumnFilter: PropTypes.func,
  right: PropTypes.bool,
  itemsHeader: PropTypes.string,
  extraItemsCategory: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  extraItems: PropTypes.object,
  extraItemsHeader: PropTypes.string,
  setColumnFilters: PropTypes.func,
  setMyRegionFilter: PropTypes.func,
};


export default DropDown;

import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';

import './App.css';

import MainNavbar from './components/navigation/MainNavbar';
import Login from './components/authentication/Login';
import PrivateRoute from './components/authentication/PrivateRoute';
import LoggedInRoute from './components/authentication/LoggedInRoute';
import PulseCheckTable from './components/pulse-check/pulse-check-table/PulseCheckTable';
import PulseCheckData from './components/pulse-check/PulseCheckData';
import AnalystPulseCheckEditor from './components/pulse-check/AnalystPulseCheckEditor';
import PulseCheckSummaryReport from './components/pulse-check/summary-report/PulseCheckSummaryReport';
import authService from './utils/auth-service';
import LanguageService from './utils/language-service';
import TokenInterceptor from './components/authentication/TokenInterceptor';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: authService.isAuthenticated(),
      authInfo: authService.getAuthInfo(),
      language: LanguageService.getLanguage(),
      languageMessages: LanguageService.getMessages(),
    };
  }

  onLoginSuccess = (authInfo) => {
    LanguageService.setLanguage(LanguageService.resolveLanguage());

    this.setState({
      isAuthenticated: true,
      authInfo,
      language: LanguageService.getLanguage(),
      languageMessages: LanguageService.getMessages(),
    });
  }

  onLogoutSuccess = () => {
    authService.signOut();
    LanguageService.setLanguage(LanguageService.resolveLanguage());

    this.setState({
      isAuthenticated: authService.isAuthenticated(),
      authInfo: authService.getAuthInfo(),
      language: LanguageService.getLanguage(),
      languageMessages: LanguageService.getMessages(),
    });
  }

  onSwitchLanguageTo = (language) => {
    LanguageService.setLanguage(language);

    this.setState({
      language: LanguageService.getLanguage(),
      languageMessages: LanguageService.getMessages(),
    });
  }

  render() {
    const {
      isAuthenticated, authInfo, language, languageMessages,
    } = this.state;
    const email = authInfo && authInfo.profileModel
      ? authInfo.profileModel.email
      : null;

    const region = authInfo && authInfo.profileModel && authInfo.profileModel.region
      ? `/queue?count=10&page=1&sortBy=desc&sortField=id&region=${authInfo.profileModel.region}`
      : '/queue?count=10&page=1&sortBy=desc&sortField=id';

    return (
      <IntlProvider
        key={language}
        locale={language}
        messages={languageMessages}
        defaultLocale={LanguageService.defaultLanguage}
      >
        <div className="App">
          <TokenInterceptor onRefreshTokenFailure={this.onLogoutSuccess} />
          <BrowserRouter>
            <React.Fragment>
              <MainNavbar
                isAuthenticated={isAuthenticated}
                email={email}
                onLogoutSuccess={this.onLogoutSuccess}
                onSwitchLanguageTo={this.onSwitchLanguageTo}
                region={region}
              />
              <Container fluid id="container">
                <LoggedInRoute
                  exact
                  path="/login"
                  isAuthenticated={isAuthenticated}
                  region={region}
                  onLoginSuccess={this.onLoginSuccess}
                  component={Login}
                />
                <Route exact path="/">
                  <Redirect to={region} />
                </Route>
                <PrivateRoute exact path="/:searchParams" isAuthenticated={isAuthenticated} component={PulseCheckTable} />
                <PrivateRoute path="/pulseCheckData/:id" isAuthenticated={isAuthenticated} component={PulseCheckData} />
                <PrivateRoute path="/pulseCheckEditor" isAuthenticated={isAuthenticated} component={AnalystPulseCheckEditor} />
                <Route path="/pulseCheckReport/:id/:secret?" component={PulseCheckSummaryReport} />
              </Container>
            </React.Fragment>
          </BrowserRouter>
        </div>
      </IntlProvider>
    );
  }
}

export default App;

import React, { Component } from 'react';
// import { IntlShape } from 'react-intl';
import PropTypes from 'prop-types';
import StaticStrings from './StaticStrings';
import { IntlPropType } from '../../utils/language-service';

function getIcon(assetId) {
  switch (assetId) {
    case 'Green':
      return 'fa fa-check-circle fa-2x';
    case 'Yellow':
      return 'fa fa-minus-circle fa-2x';
    case 'Red':
      return 'fa fa-exclamation-circle fa-2x';
    default:
      return '';
  }
}

function getText(assetId, intl) {
  switch (assetId) {
    case 'Green':
      return intl.formatMessage({ id: 'RecommendationNoActionRequired' });
    case 'Yellow':
      return intl.formatMessage({ id: 'RecommendationAtNextEvent' });
    case 'Red':
      return intl.formatMessage({ id: 'RecommendationImmediateAction' });
    default:
      return '';
  }
}

const DisplayAssetStatusBuilder = ({ recommendation, statusId, intl }) => (
  <tr key={recommendation}>
    <td><i className={getIcon(statusId)} aria-hidden="true" /></td>
    <td><strong>{ recommendation.replace(/([A-Z])/g, ' $1').trim() }</strong></td>
    <td>{ getText(statusId, intl) }</td>
  </tr>
);
DisplayAssetStatusBuilder.propTypes = {
  recommendation: PropTypes.string.isRequired,
  statusId: PropTypes.string.isRequired,
  intl: IntlPropType.isRequired,
};

class DisplayAssetStatus extends Component {
  static FindDisplayText(recommendationType, statusId, intl) {
    let text = '';
    const concepts = StaticStrings.getRecommendationConcepts(intl);

    switch (recommendationType) {
      case 'Bearings':
        text = concepts.Bearings;
        break;
      case 'Balance':
        text = concepts.Balance;
        break;
      case 'Alignment':
        text = concepts.Alignment;
        break;
      case 'Looseness':
        text = concepts.Looseness;
        break;
      case 'Lubrication':
        text = concepts.Lubrication;
        break;
      case 'Electrical':
        text = concepts.Electrical;
        break;
      case 'Cavitation':
        text = concepts.Cavitation;
        break;
      case 'Flow':
        text = concepts.Flow;
        break;
      default:
        text = undefined;
        break;
    }

    if (text) {
      text = (
        <DisplayAssetStatusBuilder
          key={recommendationType}
          recommendation={text}
          statusId={statusId}
          intl={intl}
        />);
    }

    return text;
  }
}

export default DisplayAssetStatus;

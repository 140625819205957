import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalBody } from 'reactstrap';

import authService from '../../utils/auth-service';
import { IntlPropType } from '../../utils/language-service';
import CustomerCommentTable from './CustomerCommentTable';
import CustomerInformationTable from './CustomerInformationTable';
import BearingInformationTable from './BearingInformationTable';
import AssetInformationTable from './AssetInformationTable';
import SensorInformationTable from './SensorInformationTable';
import ActiveThresholdsTable from './ActiveThresholdsTable';
import TemperatureDataTable from './TemperatureDataTable';
import PreviousPulseChecksTable from './PreviousPulseChecksTable';
import PulseCheckTitle from './PulseCheckTitle';
import config from '../../config';
import DownloadDataContainer from './download-data/downloadDataContainer';
import AssignAnalyst from './AssignAnalyst';
import AnalystPulseCheckEditor from './AnalystPulseCheckEditor';
import ClosePulseCheck from './ClosePulseCheck';
import CancelPulseCheck from './CancelPulseCheck';
import Chart from './Chart';
import LoadingImage from '../../images/loading-icon.png';

class PulseCheckData extends Component {
  static getMeasurementData(assetId) {
    const measurementLoginData = authService.getAuthInfo();
    const measurementQuereUrl = `/api/PulseApp/Asset/ScalarMeasurementHistory?AssetId=${assetId}&Limit=1`;
    const measurementApiUrl = new URL(measurementQuereUrl, config.API_URL);
    return Axios.get(measurementApiUrl, { headers: { Authorization: `Bearer ${measurementLoginData.accessToken}` } })
      .then(response => response.data)
      // eslint-disable-next-line no-console
      .catch(error => console.log(error));
  }

  static getChartMeasurementData(assetId) {
    const chartLoginData = authService.getAuthInfo();
    const chartQueryUrl = `/api/PulseApp/Asset/ChartData?AssetId=${assetId}`;
    const chartApiUrl = new URL(chartQueryUrl, config.API_URL);
    return Axios.get(chartApiUrl, { headers: { Authorization: `Bearer ${chartLoginData.accessToken}` } })
      .then(response => response.data)
      // eslint-disable-next-line no-console
      .catch(error => console.log(error));
  }

  static getStatusData(pulseCheckId, locale) {
    const pulseCheckLoginData = authService.getAuthInfo();
    const pulseCheckQueueUrl = `api/pulsechecks/${pulseCheckId}/Recommendations/${locale}`;
    const pulseCheckApiUrl = new URL(pulseCheckQueueUrl, config.API_URL);
    return Axios.get(pulseCheckApiUrl, { headers: { Authorization: `Bearer ${pulseCheckLoginData.accessToken}` } })
      .then((response) => {
        // ensure the data contains all the statuses, if not, populate empty ones
        const scrubbed = {
          displayRecommendation: response.data.displayRecommendation,
          assetRecommendations: ['Bearings', 'Balance', 'Alignment', 'Looseness', 'Lubrication', 'Electrical', 'Cavitation', 'Flow']
            .map(item => ({ heading: item, statusColor: 'Unspecified' })),
        };

        response.data.assetRecommendations.forEach((element) => {
          const indexFound = scrubbed.assetRecommendations
            .findIndex(r => r.heading === element.heading);
          if (indexFound >= 0) scrubbed.assetRecommendations[indexFound] = element;
        });

        return scrubbed;
      })
      // eslint-disable-next-line no-console
      .catch(error => console.error(error));
  }

  static getDiagramData(assetType) {
    if (!assetType) return undefined;
    const diagramAssetLoginData = authService.getAuthInfo();
    const diagramAssetTypeUrl = `api/diagrams/assettypes/${encodeURI(assetType)}`;
    const diagramUri = new URL(diagramAssetTypeUrl, config.API_URL);
    return Axios.get(diagramUri, { headers: { Authorization: `Bearer ${diagramAssetLoginData.accessToken}` } })
      .then(response => response.data)
      // eslint-disable-next-line no-console
      .catch(error => console.error(error));
  }

  constructor(props) {
    super(props);

    this.state = {
      data: null,
      pulseCheckIsComplete: true,
      closed: false,
      cancelled: false,
      showNameplateModal: false,
      showImageModal: false,
      nameplateImage: { src: LoadingImage, alt: '' },
      assetImage: { src: LoadingImage, alt: '' },
    };
    this.onCompletedPulseCheck = this.onCompletedPulseCheck.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onAnalystAssign = this.onAnalystAssign.bind(this);
    this.toggleImageModal = this.toggleImageModal.bind(this);
    this.toggleNameplateModal = this.toggleNameplateModal.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.state = ({
      pulseCheckId: match.params.id,
      isMounted: true,
    });
    const { pulseCheckId } = this.state;
    this.getData(pulseCheckId);
  }

  componentWillUnmount() {
    const t = this;
    t.state = ({
      isMounted: false,
    });
  }

  onAnalystAssign(bool) {
    const t = this;
    t.setState({
      hasAnalyst: bool,
      canEdit: bool,
    });
  }

  onCompletedPulseCheck(bool) {
    const t = this;
    t.setState({
      pulseCheckIsComplete: bool,
    });
  }

  onClose(bool) {
    const t = this;
    t.setState({
      closed: bool,
    });
  }

  onCancel(bool) {
    const t = this;
    t.setState({
      cancelled: bool,
    });
  }

  getData(pulseCheckId) {
    const pulseCheckLoginData = authService.getAuthInfo();
    const pulseCheckQueueUrl = `api/pulsechecks/${pulseCheckId}`;
    const pulseCheckApiUrl = new URL(pulseCheckQueueUrl, config.API_URL);
    const t = this;
    const { intl } = this.props;

    Axios.get(pulseCheckApiUrl, { headers: { Authorization: `Bearer ${pulseCheckLoginData.accessToken}` } })
      .then((response) => {
        if (response.data) {
          const pulseCheckData = response.data;

          const measurementPromise = PulseCheckData.getMeasurementData(pulseCheckData.assetId);
          const chartPromise = PulseCheckData.getChartMeasurementData(pulseCheckData.assetId);
          const statusPromise = PulseCheckData.getStatusData(pulseCheckData.id, intl.locale);
          const diagramPromise = PulseCheckData.getDiagramData(pulseCheckData.asset.assetType);

          Promise.all([measurementPromise, chartPromise, statusPromise, diagramPromise])
            .then((result) => {
              if (t.state.isMounted) {
                const [measurementData, chartData, statusData, diagramData] = result;
                const canAssign = authService.getAuthInfo().profileModel.permissions.includes('ManageAllPulseChecks');
                const canEdit = authService.getAuthInfo().profileModel.permissions.includes('ManageAllPulseChecks');
                t.setState({
                  data: {
                    pulseCheckData,
                    measurementData,
                    statusData,
                    diagramData,
                    chartData,
                  },
                  canEdit,
                  canAssign,
                  assetId: pulseCheckData.assetId,
                  closed: pulseCheckData.status === 'Closed',
                  hasAnalyst: pulseCheckData.analystId !== null,
                });

                const {
                  assetId,
                  asset,
                } = pulseCheckData;

                const namePlatePicture = asset ? asset.nameplatePicture : null;
                const assetPicture = asset ? asset.assetPicture : null;

                // eslint-disable-next-line no-undef
                const imageElement = document.getElementById('asset-type-picture');

                if (diagramData && diagramData.bearingDesignationImage) {
                  this.downloadDiagramContent(diagramData.bearingDesignationImage, 'asset-type-picture');
                } else if (imageElement) {
                  imageElement.src = '';
                  imageElement.alt = intl.formatMessage({ id: 'NoImageAddedAlternateText' });
                }
                this.downloadFileContent(assetId, namePlatePicture, 'nameplatePicture');
                this.downloadFileContent(assetId, assetPicture, 'assetPicture');
              }
            });
        }
      });
  }

  downloadFileContent(assetId, attachmentId, imageId) {
    const loginData = authService.getAuthInfo();
    const attachmentDownloadUrl = `api/Assets/${assetId}/Attachments/${attachmentId}`;
    const attachmentApiUrl = new URL(attachmentDownloadUrl, config.API_URL);
    const { intl } = this.props;
    const { nameplateImage, assetImage } = this.state;

    return Axios.get(attachmentApiUrl, { responseType: 'blob', headers: { Authorization: `Bearer ${loginData.accessToken}` } })
      .then((response) => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();


        reader.onload = (e) => {
          switch (imageId) {
            case 'nameplatePicture':
              this.setState({ nameplateImage: { ...nameplateImage, src: e.target.result } });
              break;
            case 'assetPicture':
              this.setState({ assetImage: { ...assetImage, src: e.target.result } });
              break;
            default:
              break;
          }
        };

        // eslint-disable-next-line no-undef
        reader.readAsDataURL(new Blob([response.data]));
      })
      .catch(() => {
        switch (imageId) {
          case 'nameplatePicture':
            this.setState({ nameplateImage: { src: '', alt: intl.formatMessage({ id: 'NoImageAddedAlternateText' }) } });
            break;
          case 'assetPicture':
            this.setState({ assetImage: { src: '', alt: intl.formatMessage({ id: 'NoImageAddedAlternateText' }) } });
            break;
          default:
            break;
        }
      });
  }

  downloadDiagramContent(diagramName, imageId) {
    const diagramLoginData = authService.getAuthInfo();
    const diagramSrcUrl = `api/Diagrams/Download/${encodeURI(diagramName)}`;
    const diagramUrl = new URL(diagramSrcUrl, config.API_URL);
    const { intl } = this.props;

    return Axios.get(diagramUrl, { responseType: 'blob', headers: { Authorization: `Bearer ${diagramLoginData.accessToken}` } })
      .then((response) => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.onload = (function onLoad() {
          return function setDiagramSrc(e) {
            /* eslint-disable no-undef */
            if (document.getElementById(imageId) !== null) {
              document.getElementById(imageId).src = e.target.result;
            }
            /* eslint-enable no-undef */
          };
        }(this));
        // eslint-disable-next-line no-undef
        reader.readAsDataURL(new Blob([response.data]));
      })
      .catch(() => {
        /*  eslint-disable no-undef */
        if (document.getElementById(imageId)) {
          document.getElementById(imageId).src = '';
          document.getElementById(imageId).alt = intl.formatMessage({ id: 'NoImageAddedAlternateText' });
        /* esline-enable no-undef */
        }
      });
  }

  toggleNameplateModal() {
    const { showNameplateModal, nameplateImage } = this.state;
    this.setState({ showNameplateModal: nameplateImage && nameplateImage.src && nameplateImage.src !== '' ? !showNameplateModal : false });
  }

  toggleImageModal() {
    const { showImageModal, assetImage } = this.state;
    this.setState({ showImageModal: assetImage && assetImage.src && assetImage.src !== '' ? !showImageModal : false });
  }

  render() {
    const {
      data, pulseCheckId, hasAnalyst, pulseCheckIsComplete, closed, cancelled, canEdit, canAssign,
      showImageModal, showNameplateModal, nameplateImage, assetImage,
    } = this.state;
    const { intl, history } = this.props;

    return (
      <div className="Data-container">
        {
          data !== null && pulseCheckId !== null
            ? (
              <div className="Page-two-container-row">
                <div className="Page-two-container-tables">
                  <PulseCheckTitle
                    pulseCheckData={data.pulseCheckData}
                    cancelled={cancelled}
                  />
                  <div className="Page-two-top">
                    <div className="Pulse-check-data-table-div">
                      <div className="Pulse-check-data-table-left-div">
                        <CustomerInformationTable pulseCheckData={data.pulseCheckData} />
                        <BearingInformationTable assetData={data.pulseCheckData.asset} />
                      </div>
                      <div className="Pulse-check-data-table-right-div">
                        <AssetInformationTable assetData={data.pulseCheckData.asset} />
                        <SensorInformationTable sensorData={data.pulseCheckData.sensor} />
                        <ActiveThresholdsTable pulseCheckData={data.pulseCheckData} />
                        <TemperatureDataTable measurementData={data.measurementData} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Page-two-container-pictures">
                  <div className="asset-image-container">
                    <div id="asset-type-picture-div">
                      <img
                        id="asset-type-picture"
                        src={LoadingImage}
                        alt={intl.formatMessage({ id: 'AssetTypeImageAlternateText' })}
                      />
                      <p>
                        <FormattedMessage id="AssetTypeLabel" />
                      </p>
                    </div>
                  </div>
                  <div className="asset-image-container">
                    <div id="Asset-picture-div">
                      <img
                        onClick={this.toggleImageModal}
                        onKeyUp={this.toggleImageModal}
                        role="presentation"
                        id="assetPicture"
                        src={assetImage.src}
                        alt={assetImage.alt || intl.formatMessage({ id: 'AssetImageAlternateText' })}
                      />
                      <p>
                        <FormattedMessage id="AssetImageLabel" />
                      </p>
                    </div>
                    <Modal
                      isOpen={showImageModal}
                      toggle={this.toggleImageModal}
                      size="lg"
                    >
                      <ModalBody>
                        <img
                          id="assetPictureModal"
                          src={assetImage.src}
                          alt={assetImage.alt || intl.formatMessage({ id: 'AssetImageAlternateText' })}
                        />
                      </ModalBody>
                    </Modal>
                    <div id="Name-plate-picture-div">
                      <img
                        onClick={this.toggleNameplateModal}
                        onKeyUp={this.toggleNameplateModal}
                        role="presentation"
                        id="nameplatePicture"
                        src={nameplateImage.src}
                        alt={nameplateImage.alt || intl.formatMessage({ id: 'NamePlateImageAlternateText' })}
                      />
                      <p>
                        <FormattedMessage id="NamePlateImageLabel" />
                      </p>
                    </div>
                    <Modal
                      isOpen={showNameplateModal}
                      toggle={this.toggleNameplateModal}
                      size="lg"
                    >
                      <ModalBody>
                        <img
                          id="nameplatePictureModal"
                          src={nameplateImage.src}
                          alt={nameplateImage.alt || intl.formatMessage({ id: 'NamePlateImageAlternateText' })}
                        />
                      </ModalBody>
                    </Modal>
                  </div>
                  <div className="comment-container">
                    <CustomerCommentTable pulseCheckData={data.pulseCheckData} />
                  </div>
                </div>
              </div>
            )
            : (
              <div>
                <div className="Page-two-container-tables">
                  <div className="Page-two-title">
                    <h3>
                      <FormattedMessage
                        id="PulseCheckForCompanyAndCountryText"
                        values={{
                          company: '',
                          country: '',
                        }}
                      />
                    </h3>
                    <p>
                      <FormattedMessage
                        id="PulseCheckIdText"
                        values={{ id: '' }}
                      />
                    </p>
                  </div>
                  <div className="Page-two-top">
                    <div className="Pulse-check-data-table-div">
                      <div className="Pulse-check-data-table-left-div">
                        <CustomerInformationTable pulseCheckData={null} />
                        <BearingInformationTable assetData={null} />
                      </div>
                      <div className="Pulse-check-data-table-right-div">
                        <AssetInformationTable assetData={null} />
                        <SensorInformationTable sensorData={null} />
                        <ActiveThresholdsTable pulseCheckData={null} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Page-two-container-pictures">
                  <div className="Asset-image-container">
                    <img id="asset-type-picture" src="" alt="This is the asset type." />
                  </div>
                  <div className="Asset-image-container">
                    <img id="Asset-picture" src="" alt="This is the asset." />
                    <img id="Name-plate-picture" src="" alt="This is the nameplate." />
                  </div>
                  <div className="comment-container">
                    <CustomerCommentTable pulseCheckData={null} />
                  </div>
                </div>
              </div>
            )
        }
        {data !== null && data.chartData !== null && data.chartData.acceleration !== null
          && data.chartData.temperature !== null && data.chartData.velocity !== null
          && pulseCheckId !== null
          ? (
            <div className="Chart-div">
              <Chart
                chartData={data.chartData}
                pulseCheckData={data.pulseCheckData}
              />
            </div>
          )
          : (
            <div className="Chart-div">
              <div className="Fail-chart-div">
                {
                  data !== null && data.chartData !== null && data.chartData.acceleration === null
                  && data.chartData.temperature === null && data.chartData.velocity === null
                    && 'Chart data could not render.'
                }
                {
                  data === null && 'Loading...'
                }
              </div>
            </div>
          )
        }
        { data !== null && data.chartData !== null && data.pulseCheckData
          ? (<PreviousPulseChecksTable
            previousPulseCheckTableData={data.chartData.previousPulseChecksTable}
            history={history}
          />
          ) : <div />
        }
        <div className="Page-two-button-container">
          {data !== null && data.pulseCheckData.id !== undefined && canAssign
            ? (
              <AssignAnalyst
                pulseCheckId={data.pulseCheckData.id}
                hasAnalyst={hasAnalyst}
                status={data.pulseCheckData.status}
                closed={closed}
                cancelled={cancelled}
                onAnalystAssign={this.onAnalystAssign}
              />
            )
            : <div />
          }
          {data !== null && data.pulseCheckData.id !== undefined
            ? (
              <DownloadDataContainer
                pulseCheckId={data.pulseCheckData.id}
              />
            )
            : <div />
          }
          {data !== null && canEdit
            ? (
              <ClosePulseCheck
                pulseCheckId={data.pulseCheckData.id}
                hasAnalyst={hasAnalyst}
                status={data.pulseCheckData.status}
                pulseCheckIsComplete={pulseCheckIsComplete}
                cancelled={cancelled}
                onClose={this.onClose}
              />
            )
            : <div />
        }
          {data !== null && canEdit
            ? (
              <CancelPulseCheck
                pulseCheckId={data.pulseCheckData.id}
                hasAnalyst={hasAnalyst}
                status={data.pulseCheckData.status}
                closed={closed}
                onCancel={this.onCancel}
              />
            )
            : <div />
        }
        </div>
        <h4 className="Page-two-analyst-title">
          <FormattedMessage id="AnalystActionsLabel" />
        </h4>
        {data !== null && closed !== null
          ? (
            <AnalystPulseCheckEditor
              pulseCheckId={data.pulseCheckData.id}
              statusData={data.statusData}
              status={data.pulseCheckData.status}
              pulseCheckData={data.pulseCheckData}
              onCompletedPulseCheck={this.onCompletedPulseCheck}
              closed={closed}
              cancelled={cancelled}
              canEdit={canEdit}
            />
          )
          : <div />
        }
      </div>
    );
  }
}

PulseCheckData.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  intl: IntlPropType.isRequired,
};

export default injectIntl(withRouter(PulseCheckData));

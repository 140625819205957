import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import authService from '../../utils/auth-service';
import config from '../../config';

class AssignAnalystBtn extends Component {
  static assignPulseCheck(pulseCheckId) {
    const pulseCheckLoginData = authService.getAuthInfo();
    const pulseCheckAssignUrl = `api/pulsechecks/${pulseCheckId}/Assign`;
    const pulseCheckApiUrl = new URL(pulseCheckAssignUrl, config.API_URL);

    return Axios.post(pulseCheckApiUrl, {}, { headers: { Authorization: `Bearer ${pulseCheckLoginData.accessToken}` } })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error, ' error');
      });
  }

  static unassignPulseCheck(pulseCheckId) {
    const pulseCheckLoginData = authService.getAuthInfo();
    const pulseCheckAssignUrl = `api/pulsechecks/${pulseCheckId}/Unassign`;
    const pulseCheckApiUrl = new URL(pulseCheckAssignUrl, config.API_URL);

    return Axios.post(pulseCheckApiUrl, {}, { headers: { Authorization: `Bearer ${pulseCheckLoginData.accessToken}` } })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error, ' error');
      });
  }

  constructor(props) {
    super(props);

    const t = this;
    const { hasAnalyst } = this.props;
    t.state = {
      hasAnalyst,
    };

    this.handleAssignBtnClick = this.handleAssignBtnClick.bind(this);
  }

  handleAssignBtnClick(pulseCheckId) {
    this.setState(prevState => ({
      hasAnalyst: !prevState.hasAnalyst,
    }));

    const t = this;

    if (t.state.hasAnalyst) {
      AssignAnalystBtn.unassignPulseCheck(pulseCheckId);
      t.props.onAnalystAssign(false);
    } else {
      AssignAnalystBtn.assignPulseCheck(pulseCheckId);
      t.props.onAnalystAssign(true);
    }
  }

  render() {
    const {
      status, pulseCheckId, closed, cancelled,
    } = this.props;
    const { hasAnalyst } = this.state;

    return (
      <div>
        {
          (status === 'Open' || status === 'Pending') && !closed && !cancelled
            ? (
              <Button color="primary" onClick={() => this.handleAssignBtnClick(pulseCheckId)}>
                <FormattedMessage id={hasAnalyst ? 'UnassignPulseCheckLabel' : 'AssignPulseCheckLabel'} />
              </Button>
            )
            : <div />
        }
      </div>
    );
  }
}

AssignAnalystBtn.propTypes = {
  hasAnalyst: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  pulseCheckId: PropTypes.number.isRequired,
  closed: PropTypes.bool.isRequired,
  cancelled: PropTypes.bool.isRequired,
};

export default AssignAnalystBtn;

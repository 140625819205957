import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

function createUrl(url) {
  if (!url.includes('https://')) {
    return `https://${url}`;
  }
  return url;
}

function buildAdvertisement(advertisement) {
  if (advertisement === undefined || advertisement === null || advertisement === []) {
    return (<div className="Advertisement" />);
  }
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="Advertisement"
      href={createUrl(advertisement.linkUrl)}
    >
      <span className="Advertisement-label">{advertisement.description}</span>
      <img
        className="Advertisement-image"
        src={advertisement.imageUrl}
        alt={advertisement.description}
      />
    </a>
  );
}

const bearings = advertisement => (
  <div>
    <h5><FormattedMessage id="Recommendation-Bearings" /></h5>
    <ol>
      <li><FormattedMessage id="BearingStatusMessageYellowAlarm" /></li>
      <li><FormattedMessage id="BearingStatusMessageRedAlarm" /></li>
      <li><FormattedMessage id="BearingStatusMessageContactForDamage" /></li>
    </ol>
    {buildAdvertisement(advertisement)}
  </div>);

const balance = advertisement => (
  <div>
    <h5><FormattedMessage id="Recommendation-Balance" /></h5>
    <ol>
      <li><FormattedMessage id="BalanceStatusMessageInspectForAccumulationAndCorrosion" /></li>
      <li><FormattedMessage id="BalanceStatusMessageInspectStructuralIntegrity" /></li>
      <li><FormattedMessage id="BalanceStatusMessageMeasureRunOut" /></li>
      <li><FormattedMessage id="BalanceStatusMessageCheckBalance" /></li>
    </ol>
    {buildAdvertisement(advertisement)}
  </div>);

const alignment = advertisement => (
  <div>
    <h5><FormattedMessage id="Recommendation-Alignment" /></h5>
    <ol>
      <li><FormattedMessage id="AlignmentStatusMessageCheckCouplingCondition" /></li>
      <li><FormattedMessage id="AlignmentStatusMessageMeasureRunOut" /></li>
      <li><FormattedMessage id="AlignmentStatusMessagePerformPrecisionAlignment" /></li>
    </ol>
    {buildAdvertisement(advertisement)}
  </div>);

const looseness = advertisement => (
  <div>
    <h5><FormattedMessage id="Recommendation-Looseness" /></h5>
    <ol>
      <li><FormattedMessage id="LoosenessStatusMessageCheckFasteners" /></li>
      <li><FormattedMessage id="LoosenessStatusMessageInspectIntegrity" /></li>
      <li><FormattedMessage id="LoosenessStatusMessageMeasureFit" /></li>
      <li><FormattedMessage id="LoosenessStatusMessageInspectForExcessiveWear" /></li>
    </ol>
    {buildAdvertisement(advertisement)}
  </div>);

const lubrication = advertisement => (
  <div>
    <h5><FormattedMessage id="Recommendation-Lubrication" /></h5>
    <ol>
      <li><FormattedMessage id="LubricationStatusMessageInspectForContamination" /></li>
      <li><FormattedMessage id="LubricationStatusMessageCheckLubrication" /></li>
      <li><FormattedMessage id="LubricationStatusMessageUseRecommendedMethod" /></li>
    </ol>
    {buildAdvertisement(advertisement)}
  </div>);

const electrical = advertisement => (
  <div>
    <h5><FormattedMessage id="Recommendation-Electrical" /></h5>
    <ol>
      <li><FormattedMessage id="ElectricalStatusMessageCheckAssetMounting" /></li>
      <li><FormattedMessage id="ElectricalStatusMessageCheckLoosingOnConnections" /></li>
      <li><FormattedMessage id="ElectricalStatusMessageCheckMotorDriveOperation" /></li>
    </ol>
    {buildAdvertisement(advertisement)}
  </div>);

const cavitation = advertisement => (
  <div>
    <h5><FormattedMessage id="Recommendation-Cavitation" /></h5>
    <ol>
      <li><FormattedMessage id="CavitationStatusMessageCheckSuctionPressure" /></li>
      <li><FormattedMessage id="CavitationStatusMessageCheckFluidFlow" /></li>
      <li><FormattedMessage id="CavitationStatusMessageInspectDamageAndAssembly" /></li>
    </ol>
    {buildAdvertisement(advertisement)}
  </div>);

const flow = advertisement => (
  <div>
    <h5><FormattedMessage id="Recommendation-Flow" /></h5>
    <ol>
      <li><FormattedMessage id="FlowStatusMessageCheckSuctionDischargePressure" /></li>
      <li><FormattedMessage id="FlowStatusMessageCheckFluidFlow" /></li>
      <li><FormattedMessage id="FlowStatusMessageInspectDamageAndAssembly" /></li>
    </ol>
    {buildAdvertisement(advertisement)}
  </div>);

class DisplayRecommendation extends Component {
  static FindDisplayText(key, advertisement) {
    let text = '';
    switch (key) {
      case 'Bearings':
        text = bearings(advertisement);
        break;
      case 'Balance':
        text = balance(advertisement);
        break;
      case 'Alignment':
        text = alignment(advertisement);
        break;
      case 'Looseness':
        text = looseness(advertisement);
        break;
      case 'Lubrication':
        text = lubrication(advertisement);
        break;
      case 'Electrical':
        text = electrical(advertisement);
        break;
      case 'Cavitation':
        text = cavitation(advertisement);
        break;
      case 'Flow':
        text = flow(advertisement);
        break;
      default:
        break;
    }
    return text;
  }
}

export default DisplayRecommendation;

import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import DisplayAssetStatus from './DisplayAssetStatus';
import { IntlPropType } from '../../utils/language-service';

class StaticAssetStatus extends Component {
  constructor(props) {
    super(props);
    const t = this;
    t.state = {
      statusData: null,
    };
  }

  componentDidUpdate(newProps) {
    const t = this;
    if (newProps !== t.state.statusData) {
      t.setState({
        statusData: newProps,
      });
    }
  }

  render() {
    // es-lint-disable-prefer-destructuring
    const t = this;
    const { intl } = this.props;
    return (
      <>
        <div className="Report-page-title">
          <h4><FormattedMessage id="AssetStatusLabel" /></h4>
          <div className="Report-line" />
        </div>
        <table className="Static-report-statuses Static-report-statuses-table">
          <tbody>
            {t.props.statusData
              ? (t.props.statusData.map(status => (
                DisplayAssetStatus.FindDisplayText(status.heading, status.statusColor, intl)
              )))
              : <tr><td>error</td></tr>
            }
          </tbody>
        </table>
      </>
    );
  }
}

StaticAssetStatus.propTypes = {
  intl: IntlPropType.isRequired,
};

export default injectIntl(StaticAssetStatus);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import { Button, ButtonGroup } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { IntlPropType } from '../../utils/language-service';

class Chart extends Component {
  constructor(props) {
    super(props);

    const { chartData, pulseCheckData } = this.props;

    this.state = {
      chartData,
      activeMeasurementTypeIndex: null,
      currentData: null,
      pulseCheckData,
      defaultThresholds: null,
      customThresholds: null,
      yAxisMin: 0,
    };

    this.setData = this.setData.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleInformationForChart = this.handleInformationForChart.bind(this);
    this.buildCoordinate = this.buildCoordinate.bind(this);
  }

  componentDidMount() {
    const t = this;
    if (t.props.chartData) {
      this.setData(t.props.chartData);
    }
  }

  setData(chartData) {
    const t = this;
    const { state } = this;
    new Promise((resolve) => {
      const accelerationDatasets = {};
      const accelerationTimeLabels = {};
      const temperatureDatasets = {};
      const temperatureTimeLabels = {};
      const velocityDatasets = {};
      const velocityTimeLabels = {};

      Object.keys(chartData.acceleration)
        .map(item => this.handleInformationForChart(
          accelerationDatasets, accelerationTimeLabels, chartData.acceleration, item,
        ));

      Object.keys(chartData.temperature)
        .map(item => this.handleInformationForChart(
          temperatureDatasets, temperatureTimeLabels, chartData.temperature, item,
        ));

      Object.keys(chartData.velocity)
        .map(item => this.handleInformationForChart(
          velocityDatasets, velocityTimeLabels, chartData.velocity, item,
        ));

      t.setState({
        acceleration: {
          datasets: accelerationDatasets,
          labels: accelerationTimeLabels,
          defaultThresholds: state.pulseCheckData.defaultThresholds.Acceleration,
          customThresholds: state.pulseCheckData.asset.usingCustomThresholds
            ? state.pulseCheckData.asset.thresholds.Acceleration : null,
        },
        temperature: {
          datasets: temperatureDatasets,
          labels: temperatureTimeLabels,
          defaultThresholds: state.pulseCheckData.defaultThresholds.Temperature,
          customThresholds: state.pulseCheckData.asset.usingCustomThresholds
            ? state.pulseCheckData.asset.thresholds.Temperature : null,
        },
        velocity: {
          datasets: velocityDatasets,
          labels: velocityTimeLabels,
          defaultThresholds: state.pulseCheckData.defaultThresholds.Velocity,
          customThresholds: state.pulseCheckData.asset.usingCustomThresholds
            ? state.pulseCheckData.asset.thresholds.Velocity : null,
        },
      });
      resolve();
    }).then(() => (t.handleTypeChange(t.state.acceleration)));
  }

  // eslint-disable-next-line class-methods-use-this
  buildCoordinate(xValue, yValue) {
    const itemResult = {};
    itemResult.x = xValue;
    itemResult.y = yValue;
    return itemResult;
  }

  handleInformationForChart(datasets, labels, source, point) {
    const currentLabels = source[point].map(m => m.timestamp);
    const starting = new Date(currentLabels[0]);
    const chartStarting = starting.setDate(starting.getDate() - 1);
    const ending = new Date(currentLabels[currentLabels.length - 1]);
    const chartEnding = ending.setDate(ending.getDate() + 1);

    currentLabels.unshift(chartStarting);
    currentLabels.push(chartEnding);
    // eslint-disable-next-line no-param-reassign
    labels[point] = currentLabels;

    const data = source[point].map(m => this.buildCoordinate(m.timestamp, m.measurement));
    data.unshift(this.buildCoordinate(chartStarting, null));
    data.push(this.buildCoordinate(chartEnding, null));
    // eslint-disable-next-line no-param-reassign
    datasets[point] = data;
  }

  handleTypeChange(type) {
    const datasets = [];
    const data = {};
    const { acceleration, temperature, velocity } = this.state;
    const colors = ['#FF0000', '#00FF00', '#008000', '#0000FF', '#800080', '#787879', '#df2e90', '#ff8d00'];

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in type.datasets) {
      const obj = {};
      obj.data = type.datasets[key];
      obj.fill = false;
      obj.label = key;
      datasets.push(obj);
      data.datasets = datasets;
      data.labels = type.labels[key];
    }

    // eslint-disable-next-line no-nested-ternary
    data.datasets.sort((a, b) => ((a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)));

    data.datasets.forEach((item, i) => {
      // eslint-disable-next-line no-param-reassign
      item.borderColor = colors[i];
      // eslint-disable-next-line no-param-reassign
      item.backgroundColor = colors[i];
    });

    let defaultThresholds;
    switch (type) {
      case acceleration:
        // eslint-disable-next-line prefer-destructuring
        defaultThresholds = acceleration.defaultThresholds;
        break;
      case temperature:
        // eslint-disable-next-line prefer-destructuring
        defaultThresholds = temperature.defaultThresholds;
        break;
      case velocity:
        // eslint-disable-next-line prefer-destructuring
        defaultThresholds = velocity.defaultThresholds;
        break;
      default:
        defaultThresholds = null;
    }

    let customThresholds;
    switch (type) {
      case acceleration:
        // eslint-disable-next-line prefer-destructuring
        customThresholds = acceleration.customThresholds;
        break;
      case temperature:
        // eslint-disable-next-line prefer-destructuring
        customThresholds = temperature.customThresholds;
        break;
      case velocity:
        // eslint-disable-next-line prefer-destructuring
        customThresholds = velocity.customThresholds;
        break;
      default:
        customThresholds = null;
    }

    const yAxisMin = (defaultThresholds != null
      && (defaultThresholds.highAlertLevel < 1 || defaultThresholds.highDangerLevel < 1))
      || (customThresholds != null
      && (customThresholds.highAlertLevel < 1 || customThresholds.highDangerLevel < 1))
      ? -1 : 0;

    this.setState({
      currentData: data,
      // eslint-disable-next-line no-nested-ternary
      activeMeasurementTypeIndex: type === acceleration ? 0 : type === temperature ? 1 : 2,
      defaultThresholds,
      customThresholds,
      yAxisMin,
    });
  }

  render() {
    const { intl } = this.props;
    let chartText = <FormattedMessage id="LoadingLabel" />;
    // eslint-disable-next-line no-return-assign
    setTimeout(() => (chartText = intl.formatMessage({ id: 'ChartDataCouldNotRederMessage' })), 3000);
    const {
      acceleration,
      temperature,
      velocity,
      currentData,
      activeMeasurementTypeIndex,
      defaultThresholds,
      customThresholds,
      yAxisMin,
      chartData,
    } = this.state;

    /* eslint-disable no-nested-ternary */
    const options = {
      title: {
        display: true,
        text: activeMeasurementTypeIndex === 0 ? chartText = intl.formatMessage({ id: 'AccelerationTrendLabel' })
          : activeMeasurementTypeIndex === 1 ? intl.formatMessage({ id: 'TemperatureTrendLabel' }) : intl.formatMessage({ id: 'VelocityTrendLabel' }),
        fontSize: 20,
      },
      legend: {
        display: true,
        position: 'right',
      },
      elements: {
        line: {
          tension: 0,
        },
        point: {
          radius: 6,
          hoverRadius: 6,
        },
      },
      scales: {
        yAxes: [{
          ticks: {
            min: yAxisMin,
          },
          scaleLabel: {
            display: true,
            labelString: activeMeasurementTypeIndex === 0 ? chartText = intl.formatMessage({ id: 'AccelerationEnvelopingPeakToPeakLabel' })
              : activeMeasurementTypeIndex === 1 ? intl.formatMessage({ id: 'CelsiusGradesLabel' }) : intl.formatMessage({ id: 'RmsUnitLabel' }),
            fontSize: 16,
            padding: 20,
          },
        }],
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage({ id: 'TimeLabel' }),
            fontSize: 16,
            padding: 20,
          },
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'YYYY-MM-DD',
            },
            ticks: {
              autoSkip: true,
              min: 0,
              maxRotation: 90,
              minRotation: 90,
              maxTicksLimit: 15,
            },
          },
        }],
      },
      annotation: {
        drawTime: 'afterDatasetsDraw',
        annotations: [],
      },
    };
    /* eslint-enable no-nested-ternary */

    if (defaultThresholds !== undefined && defaultThresholds !== null) {
      options.annotation.annotations = [
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: defaultThresholds.highAlertLevel !== undefined
            ? defaultThresholds.highAlertLevel : null,
          borderColor: 'rgb(240, 173, 78)',
          borderWidth: 4,
          label: {
            enabled: true,
            content: intl.formatMessage({ id: 'DefaultAlertLabel' },
              { number: defaultThresholds !== null && defaultThresholds.highAlertLevel !== undefined ? Number.parseFloat(defaultThresholds.highAlertLevel).toFixed(4) : '' }),
            yPadding: 4,
            position: 'left',
          },
        },
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: defaultThresholds !== null && defaultThresholds.highDangerLevel !== undefined
            ? defaultThresholds.highDangerLevel : null,
          borderColor: 'rgb(217, 83, 79)',
          borderWidth: 4,
          label: {
            enabled: true,
            content: intl.formatMessage({ id: 'DefaultDangerLabel' },
              { number: defaultThresholds !== null && defaultThresholds.highDangerLevel !== undefined ? Number.parseFloat(defaultThresholds.highDangerLevel).toFixed(4) : '' }),
            yPadding: 4,
            position: 'left',
          },
        },
      ];
    }

    Object.entries(chartData.previousPulseChecks).forEach(([key, value]) => {
      options.annotation.annotations.unshift({
        type: 'line',
        id: `vline-${key}`,
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: moment(value, moment.ISO_8601).format(),
        borderColor: 'rgb(66,139,202)',
        borderWidth: 2,
      });
    });

    if (customThresholds !== undefined && customThresholds !== null) {
      options.annotation.annotations.push({
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: customThresholds.highAlertLevel !== undefined
          ? customThresholds.highAlertLevel : null,
        borderColor: 'rgb(240, 173, 78)',
        borderWidth: 4,
        label: {
          enabled: true,
          content: intl.formatMessage({ id: 'CustomAlertLabel' },
            { number: customThresholds !== null && customThresholds.highAlertLevel !== undefined ? Number.parseFloat(customThresholds.highAlertLevel).toFixed(4) : '' }),
          yPadding: 4,
          position: 'left',
        },
      });
      options.annotation.annotations.push({
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: customThresholds !== null && customThresholds.highDangerLevel !== undefined
          ? customThresholds.highDangerLevel : null,
        borderColor: 'rgb(217, 83, 79)',
        borderWidth: 4,
        label: {
          enabled: true,
          content: intl.formatMessage({ id: 'CustomDangerLabel' },
            { number: customThresholds !== null && customThresholds.highDangerLevel !== undefined ? Number.parseFloat(customThresholds.highDangerLevel).toFixed(4) : '' }),
          yPadding: 4,
          position: 'left',
        },
      });
    }

    return (
      <>
        {acceleration && temperature && velocity && currentData
          && (defaultThresholds || customThresholds)
          ? (
            <>
              <Line data={currentData} width={500} height={200} options={options} redraw ease />
              <ButtonGroup>
                <Button
                  color={activeMeasurementTypeIndex === 0 ? 'primary' : 'secondary'}
                  active={activeMeasurementTypeIndex === 0}
                  onClick={() => this.handleTypeChange(acceleration)}
                >
                  <FormattedMessage id="AccelerationLabel" />
                </Button>
                <Button
                  color={activeMeasurementTypeIndex === 1 ? 'primary' : 'secondary'}
                  active={activeMeasurementTypeIndex === 1}
                  onClick={() => this.handleTypeChange(temperature)}
                >
                  <FormattedMessage id="TemperatureLabel" />
                </Button>
                <Button
                  color={activeMeasurementTypeIndex === 2 ? 'primary' : 'secondary'}
                  active={activeMeasurementTypeIndex === 2}
                  onClick={() => this.handleTypeChange(velocity)}
                >
                  <FormattedMessage id="VelocityLabel" />
                </Button>
              </ButtonGroup>
            </>
          )
          : (
            <div className="Fail-chart-div">
              <p>{chartText}</p>
            </div>
          )
        }
      </>
    );
  }
}

Chart.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  pulseCheckData: PropTypes.any.isRequired,
  chartData: PropTypes.any.isRequired,
  /* eslint-enable react/forbid-prop-types */
  intl: IntlPropType.isRequired,
};

export default injectIntl(Chart);

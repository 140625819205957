/* eslint-disable no-undef */

function getConfigurationSettings() {
  if (!window.config) {
    return {
      API_URL: '',
      REPORT_RETRY_WAIT_IN_MILLISECONDS: 0,
      REPORT_RETRY_COUNT: 0,
    };
  }

  return {
    API_URL: window.config.API_URL,
    REPORT_RETRY_WAIT_IN_MILLISECONDS: window.config.REPORT_RETRY_WAIT_IN_MILLISECONDS,
    REPORT_RETRY_COUNT: window.config.REPORT_RETRY_COUNT,
  };
}

export default getConfigurationSettings();

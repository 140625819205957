import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import moment from 'moment';

import config from '../../../config';
import authService from '../../../utils/auth-service';
import { IntlPropType } from '../../../utils/language-service';
import StaticSummary from '../StaticSummary';
import StaticAssetStatus from '../StaticAssetStatus';
import Recommendations from '../Recommendations';
import Alerts from '../Alerts';
import StaticStrings from '../StaticStrings';

class PulseCheckSummaryReport extends Component {
  constructor(props) {
    super(props);

    const t = this;
    t.state = {
      pulseCheckData: null,
      assetRecommendations: [],
      displayRecommendation: {},
      advertisements: [],
      hideAssetPicture: false,
      hideTypePicture: false,
      isMounted: false,
    };
  }

  componentDidMount() {
    const t = this;
    const { match } = this.props;
    const pulseCheckId = match.params.id;

    if (match.params.secret) {
      this.loadWithSecret(pulseCheckId, match.params.secret);
    } else if (authService.isAuthenticated()) {
      this.loadWithAuthentication(pulseCheckId);
    }

    t.setState({
      isMounted: true,
    });
  }

  componentWillUnmount() {
    const t = this;
    t.setState({
      isMounted: false,
    });
  }

  getData(pulseCheckId) {
    const { intl } = this.props;
    const recommendationLoginData = authService.getAuthInfo();
    const recommendationUrl = `api/PulseChecks/${pulseCheckId}/Recommendations/${intl.locale}`;
    const recommendationApiUrl = new URL(recommendationUrl, config.API_URL);
    Axios
      .get(recommendationApiUrl, { headers: { Authorization: `Bearer ${recommendationLoginData.accessToken}` } })
      .then((recommendationResponse) => {
        if (recommendationResponse.data) {
          this.handleRecommendations(recommendationResponse.data);
        }
      });
  }

  loadWithSecret(pulseCheckId, secret) {
    const { intl } = this.props;
    const anonymousDataUrl = new URL(`api/pulsechecks/${pulseCheckId}/ViewReport/${secret}/${intl.locale}`, config.API_URL);
    Axios.get(anonymousDataUrl)
      .then((response) => {
        const t = this;
        t.setState({
          pulseCheckData: response.data.pulseCheck,
          attachment: response.data.attachment,
          diagram: response.data.diagram,
          hideAssetPicture: response.data.attachment === null,
          hideTypePicture: response.data.diagram === null,
        });
        t.handleRecommendations(response.data.recommendations);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error, ' error');
      });
  }

  loadWithAuthentication(pulseCheckId) {
    const pulseCheckLoginData = authService.getAuthInfo();
    const summaryReportUrl = new URL(`api/pulsechecks/${pulseCheckId}/summary-report`, config.API_URL);

    Axios.get(summaryReportUrl, { headers: { Authorization: `Bearer ${pulseCheckLoginData.accessToken}` } })
      .then((response) => {
        if (response.status === 200) {
          const t = this;
          t.setState({ pulseCheckData: response.data });
          t.getData(t.state.pulseCheckData.id);

          const {
            assetId,
            asset,
          } = t.state.pulseCheckData;
          const assetTypePicture = asset ? asset.assetType : null;
          const assetPicture = asset ? asset.assetPicture : null;
          this.downloadDiagramContent(assetTypePicture);
          this.downloadFileContent(assetId, assetPicture);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error, ' error');
      });
  }

  handleRecommendations(data) {
    const t = this;
    let { assetRecommendations } = data;
    assetRecommendations = Array.from(assetRecommendations);
    const displayRecommendation = data === undefined ? {} : data.displayRecommendation;
    const advertisements = data === undefined ? [] : data.advertisements;
    t.setState({
      assetRecommendations,
      displayRecommendation,
      advertisements,
    });
  }

  downloadFileContent(assetId, attachmentId) {
    const loginData = authService.getAuthInfo();
    const attachmentDownloadUrl = `api/Assets/${assetId}/Attachments/${attachmentId}`;
    const attachmentApiUrl = new URL(attachmentDownloadUrl, config.API_URL);
    const t = this;

    return Axios.get(attachmentApiUrl, { responseType: 'blob', headers: { Authorization: `Bearer ${loginData.accessToken}` } })
      .then((response) => {
        if (t.state.isMounted) {
          t.setState({ hideAssetPicture: false });
          // eslint-disable-next-line no-undef
          const reader = new FileReader();

          reader.onload = (function onLoad() {
            return function setPulseCheckAttachment(e) {
              // eslint-disable-next-line react/no-unused-state
              t.setState({ attachment: e.target.result });
            };
          }(this));

          // eslint-disable-next-line no-undef
          reader.readAsDataURL(new Blob([response.data]));
        }
      })
      .catch(() => {
        t.setState({ hideAssetPicture: true });
      });
  }

  downloadDiagramContent(assetType) {
    if (!assetType) return;
    const typeSrcUrl = `api/Diagrams/AssetTypes/${encodeURI(assetType)}`;
    const typeUrl = new URL(typeSrcUrl, config.API_URL);
    const t = this;
    const diagramLoginData = authService.getAuthInfo();

    Axios.get(typeUrl, { headers: { Authorization: `Bearer ${diagramLoginData.accessToken}` } })
      .then((typeResponse) => {
        const diagramSrcUrl = `api/Diagrams/Download/${encodeURI(typeResponse.data.bearingDesignationImage)}`;
        const diagramUrl = new URL(diagramSrcUrl, config.API_URL);

        Axios.get(diagramUrl, { responseType: 'blob', headers: { Authorization: `Bearer ${diagramLoginData.accessToken}` } })
          .then((response) => {
            t.setState({ hideTypePicture: false });
            // eslint-disable-next-line no-undef
            const reader = new FileReader();
            reader.onload = (function onLoad() {
              return function setDiagramSrc(e) {
                // eslint-disable-next-line react/no-unused-state
                t.setState({ diagram: e.target.result });
              };
            }(this));
            // eslint-disable-next-line no-undef
            reader.readAsDataURL(new Blob([response.data]));
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          });
      })
      .catch(() => {
        t.setState({ hideTypePicture: true });
      });
  }

  render() {
    const t = this;
    const { state } = t;
    const {
      assetRecommendations, displayRecommendation, pulseCheckData,
    } = state;
    const { intl } = this.props;
    const allAssetTypesI18n = StaticStrings.getAssetTypes(intl);
    if (!t.props.match.params.secret && !authService.isAuthenticated()) {
      return <Redirect to={{ pathname: '/login', state: { prevLocation: t.props.location.pathname } }} />;
    }

    return (
      <div className="Report-page">
        {state !== null
          ? (
            <>
              <Row>
                <Col sm="12" md="6">
                  <Row className="align-items-center">
                    <Col xs="4">
                      <img
                        src="../../../SKF_pulse_check_logo.jpg"
                        alt="SKF pulse check logo"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs="8" className="Pulse-check-id">
                      <h3>
                        <FormattedMessage id="PulseCheckLabel" />
                      </h3>
                      <h4>
                        {pulseCheckData === null
                          ? null
                          : (
                            <nobr>
                              <FormattedMessage id="IdDeclarationLabel" />
                              {pulseCheckData.asset.id === null ? '' : pulseCheckData.id}
                            </nobr>)
                        }
                      </h4>
                    </Col>
                  </Row>
                  {displayRecommendation === null
                    && pulseCheckData.pulseCheckSummary === null
                    ? (
                      <Row>
                        <Col>
                          <Alerts.EmptyReport />
                        </Col>
                      </Row>)
                    : null
                  }
                  {t.state.pulseCheckData !== null
                    ? (
                      <>
                        <Row className="align-items-center Report-asset-information">
                          {!t.state.hideAssetPicture ? (
                            <Col xs="12" sm="5">
                              <img
                                className="img-fluid"
                                id="assetPicture"
                                src={t.state.attachment || ''}
                                alt=""
                              />
                            </Col>
                          )
                            : <Col />
                          }
                          {!t.state.hideTypePicture ? (
                            <Col xs="12" sm="6">
                              <img
                                className="img-fluid img-thumbnail"
                                id="assetTypePicture"
                                src={t.state.diagram || ''}
                                alt=""
                              />
                            </Col>
                          )
                            : <Col />
                          }
                        </Row>
                        <Row>
                          <Col>
                            <table className="Report-data Report-asset-information">
                              <tbody>
                                <tr>
                                  <th className="Report-data-title">
                                    <FormattedMessage id="RequestedByDeclarationLabel" />
                                  </th>
                                  <td>{t.state.pulseCheckData.requester === null ? '' : `${t.state.pulseCheckData.requester.firstName} ${t.state.pulseCheckData.requester.lastName}`}</td>
                                </tr>
                                <tr>
                                  <th className="Report-data-title">
                                    <FormattedMessage id="EmailDeclarationLabel" />
                                  </th>
                                  <td>{t.state.pulseCheckData.requester === null ? '' : t.state.pulseCheckData.requester.email}</td>
                                </tr>
                                <tr>
                                  <th className="Report-data-title">
                                    <FormattedMessage id="CompanyNameDeclarationLabel" />
                                  </th>
                                  <td>{t.state.pulseCheckData.requester === null ? '' : t.state.pulseCheckData.requester.company}</td>
                                </tr>
                                <tr>
                                  <th className="Report-data-title">
                                    <FormattedMessage id="PostalCodeDeclarationLabel" />
                                  </th>
                                  <td>{t.state.pulseCheckData.requester === null ? '' : t.state.pulseCheckData.requester.zipCode}</td>
                                </tr>
                                <tr>
                                  <th className="Report-data-title">
                                    <FormattedMessage id="CompletionDateDeclarationLabel" />
                                  </th>
                                  <td>{t.state.pulseCheckData.completedDate === null ? '' : moment(t.state.pulseCheckData.completedDate).format(intl.formatMessage({ id: 'CompletionDateFormat' }))}</td>
                                </tr>
                                <tr>
                                  <th className="Report-data-title">
                                    <FormattedMessage id="AssetNameDeclarationLabel" />
                                  </th>
                                  <td>{t.state.pulseCheckData.asset.assetName === null ? '' : t.state.pulseCheckData.asset.assetName}</td>
                                </tr>
                                <tr>
                                  <th className="Report-data-title">
                                    <FormattedMessage id="AssetTagDeclarationLabel" />
                                  </th>
                                  <td>{t.state.pulseCheckData.asset.assetTag === null ? '' : t.state.pulseCheckData.asset.assetTag}</td>
                                </tr>
                                <tr>
                                  <th className="Report-data-title">
                                    <FormattedMessage id="AssetTypeDeclarationLabel" />
                                  </th>
                                  <td>{t.state.pulseCheckData.asset.assetType === null ? '' : allAssetTypesI18n[t.state.pulseCheckData.asset.assetType]}</td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                </Col>
                <Col sm="12" md="6">
                  {
                    t.state.pulseCheckData && t.state.pulseCheckData.comment
                      ? <>
                        <div className="Report-page-title">
                          <h4><FormattedMessage id="CommentLabel" /></h4>
                          <div className="Report-line" />
                        </div>
                        <div className="Report-page-text Report-page-text-summary">
                          {t.state.pulseCheckData.comment}
                        </div>
                        </>
                      : <>&nbsp;</>
                  }
                  <div className="Report-page-title">
                    <h4><FormattedMessage id="SummaryLabel" /></h4>
                    <div className="Report-line" />
                  </div>
                  {t.state.pulseCheckData !== null
                    ? <StaticSummary summaryData={t.state.pulseCheckData.pulseCheckSummary} />
                    : <StaticSummary summaryData={null} />
                  }
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col sm="12" md="6">
                  <StaticAssetStatus statusData={assetRecommendations} />
                </Col>
                <Col sm="12" md="6">
                  {t.state.pulseCheckData !== null && displayRecommendation !== null
                    && t.state.advertisements !== null
                    ? (
                      <Recommendations
                        pulseCheckId={t.state.pulseCheckData.id}
                        advertisements={t.state.advertisements}
                        recommendationData={displayRecommendation}
                        locale={t.state.pulseCheckData.locale}
                        statuses={assetRecommendations.filter(x => x.statusId !== 1)}
                      />)
                    : <div />
                  }
                </Col>
              </Row>
            </>)
          : <div><FormattedMessage id="ErrorLabel" /></div>
        }
      </div>
    );
  }
}

PulseCheckSummaryReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      secret: PropTypes.string,
    }).isRequired,
  }).isRequired,
  intl: IntlPropType.isRequired,
};

export default injectIntl(withRouter(PulseCheckSummaryReport));

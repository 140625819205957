import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlPropType } from '../../../utils/language-service';

class FlowStatus extends Component {
  constructor(props) {
    super(props);
    const t = this;
    t.state = {
      heading: 'Flow',
      statusText: '',
      isStatusSelected: null,
      previousStatuses: t.props.previousStatuses,
    };
  }

  componentDidMount() {
    this.setPreviousStatuses();
  }

  setPreviousStatuses() {
    const { previousStatuses } = this.state;
    const index = previousStatuses.findIndex(x => x.heading === 'Flow');
    const previousStatus = previousStatuses[index].statusColor;
    this.handleStatusChange(previousStatus);
  }

  handleStatusChange(selected) {
    const { intl } = this.props;
    const t = this;
    if (selected === 'Green') {
      t.setState({
        statusText: intl.formatMessage({ id: 'RecommendationNoActionRequired' }),
        isStatusSelected: selected,
      });
    }

    if (selected === 'Yellow') {
      t.setState({
        statusText: intl.formatMessage({ id: 'RecommendationAtNextEvent' }),
        isStatusSelected: selected,
      });
    }

    if (selected === 'Red') {
      this.setState({
        statusText: intl.formatMessage({ id: 'RecommendationImmediateAction' }),
        isStatusSelected: selected,
      });
    }
    t.props.onToggleButton(selected, t.state.heading);
  }

  render() {
    const { isStatusSelected, statusText } = this.state;
    const { style, readOnly } = this.props;

    return (
      <tr>
        <td><FormattedMessage id="Recommendation-Flow" /></td>
        <td>
          <ButtonGroup>
            <Button
              disabled={readOnly}
              style={isStatusSelected === 'Green' ? style.goodStatusSelected : style.buttonStyle}
              onClick={() => this.handleStatusChange('Green')}
            >
              <i
                className="fa fa-check-circle fa-2x"
                aria-hidden="true"
                style={isStatusSelected === 'Green' ? style.changeSelectedFont : style.goodStatus}
              />
            </Button>
            <Button
              disabled={readOnly}
              style={isStatusSelected === 'Yellow' ? style.warningStatusSelected : style.buttonStyle}
              onClick={() => this.handleStatusChange('Yellow')}
            >
              <i
                className="fa fa-minus-circle fa-2x"
                aria-hidden="true"
                style={isStatusSelected === 'Yellow' ? style.changeSelectedFont : style.warningStatus}
              />
            </Button>
            <Button
              disabled={readOnly}
              style={isStatusSelected === 'Red' ? style.dangerStatusSelected : style.buttonStyle}
              onClick={() => this.handleStatusChange('Red')}
            >
              <i
                className="fa fa-exclamation-circle fa-2x"
                aria-hidden="true"
                style={isStatusSelected === 'Red' ? style.changeSelectedFont : style.dangerStatus}
              />
            </Button>
          </ButtonGroup>
        </td>
        <td className="Report-status-text">{statusText}</td>
      </tr>
    );
  }
}

FlowStatus.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  style: PropTypes.shape({
    goodStatusSelected: PropTypes.object.isRequired,
    buttonStyle: PropTypes.object.isRequired,
    changeSelectedFont: PropTypes.object.isRequired,
    goodStatus: PropTypes.object.isRequired,
    warningStatusSelected: PropTypes.object.isRequired,
    warningStatus: PropTypes.object.isRequired,
    dangerStatusSelected: PropTypes.object.isRequired,
    dangerStatus: PropTypes.object.isRequired,
  }).isRequired,
  intl: IntlPropType.isRequired,
};

export default injectIntl(FlowStatus);

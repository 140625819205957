import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const AssetInformationTable = ({ assetData }) => (
  <table className="Page-two-table">
    {assetData != null
      ? (
        <tbody>
          <tr>
            <th colSpan="2" className="Table-title">
              <FormattedMessage id="AssetInformationLabel" />
            </th>
          </tr>
          <tr>
            <td><FormattedMessage id="ApplicationLabel" /></td>
            <td>{assetData.application}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="AssetTypeLabel" /></td>
            <td>{assetData.assetType}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="AssetNameLabel" /></td>
            <td>{assetData.assetName}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="AssetTagLabel" /></td>
            <td>{assetData.assetTag}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="ManufacturerLabel" /></td>
            <td>{assetData.manufacturer}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="ModelNumberLabel" /></td>
            <td>{assetData.modelNumber}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="SerialNumberLabel" /></td>
            <td>{assetData.serialNumber}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="OperatingSpeedLabel" /></td>
            <td>
              {assetData.isoThresholdCalculationSettings.isoOperatingSpeed}
              {' '}
              <FormattedMessage id="RpmUnitLabel" />
            </td>
          </tr>
          <tr>
            <td><FormattedMessage id="MachineSizeLabel" /></td>
            <td>
              {assetData.isoThresholdCalculationSettings.machineSize}
            </td>
          </tr>
          <tr>
            <td><FormattedMessage id="FlexibilityLabel" /></td>
            <td>{assetData.isoThresholdCalculationSettings.flexibility}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="PowerTransmissionLabel" /></td>
            <td>{assetData.assetConnection}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="BearingBoreSizeLabel" /></td>
            <td>
              {assetData.isoThresholdCalculationSettings.bearingBoreSize
                ? `${assetData.isoThresholdCalculationSettings.bearingBoreSize.toFixed(3)} mm`
                : null
              }
            </td>
          </tr>
        </tbody>
      )
      : (
        <tbody>
          <tr>
            <th colSpan="2" className="Table-title">
              <FormattedMessage id="AssetInformationLabel" />
            </th>
          </tr>
          <tr>
            <td><FormattedMessage id="ApplicationLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="AssetTypeLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="AssetNameLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="AssetTagLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="ManufacturerLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="ModelNumberLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="SerialNumberLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="OperatingSpeedLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="MachineSizeLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="FlexibilityLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="PowerTransmissionLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="BearingBoreSizeLabel" /></td>
            <td />
          </tr>
        </tbody>
      )}
  </table>
);

AssetInformationTable.defaultProps = {
  assetData: PropTypes.shape({
    application: PropTypes.string,
    assetType: PropTypes.string,
    assetName: PropTypes.string,
    assetTag: PropTypes.string,
    manufacturer: PropTypes.string,
    modelNumber: PropTypes.string,
    serialNumber: PropTypes.string,
    operatingSpeed: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isoThresholdCalculationSettings: PropTypes.shape({
      flexibility: PropTypes.string.isRequired,
      bearingBoreSize: null,
      bearingBoreSizeUnit: null,
    }).isRequired,
    powerTransmissions: PropTypes.string,
  }),
};

AssetInformationTable.propTypes = {
  assetData: PropTypes.shape({
    application: PropTypes.string,
    assetType: PropTypes.string,
    assetName: PropTypes.string,
    assetTag: PropTypes.string,
    manufacturer: PropTypes.string,
    modelNumber: PropTypes.string,
    serialNumber: PropTypes.string,
    operatingSpeed: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isoThresholdCalculationSettings: PropTypes.shape({
      flexibility: PropTypes.string.isRequired,
      bearingBoreSize: PropTypes.number,
      bearingBoreSizeUnit: PropTypes.string.isRequired,
    }).isRequired,
    powerTransmissions: PropTypes.string,
  }),
};

export default AssetInformationTable;

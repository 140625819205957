import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import 'font-awesome/css/font-awesome.min.css';
import BearingStatus from './asset-status/BearingStatus';
import BalanceStatus from './asset-status/BalanceStatus';
import AlignmentStatus from './asset-status/AlignmentStatus';
import LoosenessStatus from './asset-status/LoosenessStatus';
import LubricationStatus from './asset-status/LubricationStatus';
import ElectricalStatus from './asset-status/ElectricalStatus';
import CavitationStatus from './asset-status/CavitationStatus';
import FlowStatus from './asset-status/FlowStatus';

const styles = {
  changeSelectedFont: {
    color: '#fff',
  },
  goodStatus: {
    color: '#9DBB61',
  },
  warningStatus: {
    color: '#FFC000',
  },
  dangerStatus: {
    color: '#C00000',
  },
  buttonStyle: {
    backgroundColor: '#fff',
    margin: '5px',
    borderRadius: '10px',
    borderColor: '#333',
  },
  goodStatusSelected: {
    backgroundColor: '#9DBB61',
    borderRadius: '10px',
    margin: '5px',
  },
  warningStatusSelected: {
    backgroundColor: '#FFC000',
    borderRadius: '10px',
    margin: '5px',
  },
  dangerStatusSelected: {
    backgroundColor: '#C00000',
    borderRadius: '10px',
    margin: '5px',
  },
};

class AssetStatus extends Component {
  constructor(props) {
    super(props);
    const t = this;
    t.state = {
      assetStatuses: [],
      readOnly: false,
    };
  }

  componentDidMount() {
    const t = this;
    const notUnspecifiedStatus = t.props.previousStatuses.filter(x => x.statusId !== 0);
    t.setState({
      assetStatuses: notUnspecifiedStatus,
      readOnly: t.props.closed || t.props.cancelled || t.props.status === 'Closed' || t.props.status === 'Cancelled' || !t.props.canEdit,
    });
  }

  componentDidUpdate(nextProps) {
    const t = this;
    if (nextProps !== t.props) {
      t.setState({
        readOnly: nextProps.closed || nextProps.cancelled || nextProps.status === 'Closed' || nextProps.status === 'Cancelled' || !nextProps.canEdit,
      });
    }
  }

  onToggleButton = (statusColor, heading) => {
    const t = this;
    const newObj = {
      heading, statusColor,
    };
    const exists = t.state.assetStatuses.findIndex(status => (
      status.heading === newObj.heading
    ));
    if (exists > -1) {
      t.state.assetStatuses[exists] = newObj;
      t.props.onAssetStatusChange(t.state.assetStatuses);
    } else {
      t.setState({
        assetStatuses: [...t.state.assetStatuses, newObj],
      }, () => {
        t.props.onAssetStatusChange(t.state.assetStatuses);
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const t = this;
    if (nextProps !== t.props) {
      t.setState({
        readOnly: nextProps.closed || nextProps.cancelled || nextProps.status === 'Closed' || nextProps.status === 'Cancelled' || !nextProps.canEdit,
      });
    }
  }

  render() {
    const t = this;
    return (
      <div className="Report-asset-status-container">
        <div className="Report-page-title">
          <h4><FormattedMessage id="AssetStatusLabel" /></h4>
        </div>
        {t.state.readOnly !== undefined
          ? (
            <table className="table">
              <tbody>
                <BearingStatus
                  style={styles}
                  onToggleButton={this.onToggleButton}
                  previousStatuses={t.props.previousStatuses}
                  readOnly={t.state.readOnly}
                />
                <BalanceStatus
                  style={styles}
                  onToggleButton={this.onToggleButton}
                  previousStatuses={t.props.previousStatuses}
                  readOnly={t.state.readOnly}
                />
                <AlignmentStatus
                  style={styles}
                  onToggleButton={this.onToggleButton}
                  previousStatuses={t.props.previousStatuses}
                  readOnly={t.state.readOnly}
                />
                <LoosenessStatus
                  style={styles}
                  onToggleButton={this.onToggleButton}
                  previousStatuses={t.props.previousStatuses}
                  readOnly={t.state.readOnly}
                />
                <LubricationStatus
                  style={styles}
                  onToggleButton={this.onToggleButton}
                  previousStatuses={t.props.previousStatuses}
                  readOnly={t.state.readOnly}
                />
                <ElectricalStatus
                  style={styles}
                  onToggleButton={this.onToggleButton}
                  previousStatuses={t.props.previousStatuses}
                  readOnly={t.state.readOnly}
                />
                <CavitationStatus
                  style={styles}
                  onToggleButton={this.onToggleButton}
                  previousStatuses={t.props.previousStatuses}
                  readOnly={t.state.readOnly}
                />
                <FlowStatus
                  style={styles}
                  onToggleButton={this.onToggleButton}
                  previousStatuses={t.props.previousStatuses}
                  readOnly={t.state.readOnly}
                />
              </tbody>
            </table>
          )
          : <div />
        }
      </div>
    );
  }
}

export default AssetStatus;

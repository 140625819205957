import React from 'react';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const DownloadDataFailed = () => (
  <Alert color="danger" id="Download-alert-div-failed">
    <FormattedMessage id="FileDownloadSupport" />
  </Alert>
);

export default DownloadDataFailed;

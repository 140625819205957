import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import authService from '../../utils/auth-service';
import { IntlPropType } from '../../utils/language-service';
import skfLogo from '../../images/skf-logotype-center.png';
import LoginFailed from './LoginFailed';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      loggingIn: false,
      loginFailed: false,
    };

    this.signIn = this.signIn.bind(this);
  }

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  signIn(e) {
    this.setState({ loggingIn: true });
    e.preventDefault();
    const { email, password } = this.state;

    authService.signIn(email, password)
      .then((res) => {
        authService.saveAuthInfo(res.data);

        const { onLoginSuccess, history, location } = this.props;
        const prevLocation = (location && location.state && location.state.prevLocation) || null;

        onLoginSuccess(authService.getAuthInfo());
        const region = res.data.profileModel && res.data.profileModel.region
          ? `/queue?count=10&page=1&sortBy=desc&sortField=id&region=${res.data.profileModel.region}`
          : '/queue?count=10&page=1&sortBy=desc&sortField=id';
        history.push(prevLocation || region);
      })
      .catch(() => {
        this.setState({ loginFailed: true, loggingIn: false });
      });
  }

  render() {
    const {
      loginFailed, email, password, loggingIn,
    } = this.state;
    const { intl } = this.props;

    return (
      <div className="text-center">
        <img src={skfLogo} alt="" />
        <h1 className="h3 mb-3">
          <FormattedMessage id="LoginSignInRequest" />
        </h1>
        <Form className="form-signin" onSubmit={this.signIn}>
          {loginFailed
            ? <LoginFailed />
            : null}
          <FormGroup>
            <Input
              className="form-control"
              type="email"
              name="email"
              id="emailInput"
              placeholder={intl.formatMessage({ id: 'EmailAddressLabel' })}
              value={email}
              onChange={this.onInputChange}
            />

            <Input
              className="form-control"
              type="password"
              name="password"
              id="passwordInput"
              placeholder={intl.formatMessage({ id: 'PasswordLabel' })}
              value={password}
              onChange={this.onInputChange}
            />
          </FormGroup>
          <Button type="submit" color="primary" size="lg" disabled={loggingIn} className={loggingIn ? 'save-animation' : ''} block>
            {!loggingIn ? <FormattedMessage id="SignInLabel" /> : ''}
          </Button>
        </Form>
      </div>
    );
  }
}

Login.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line
  intl: IntlPropType.isRequired,
};

export default injectIntl(Login);

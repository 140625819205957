import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const CustomerInformationTable = ({ pulseCheckData }) => (
  <table className="Page-two-table">
    <thead>
      <tr>
        <th colSpan="2" className="Table-title">
          <FormattedMessage id="CustomerInformationLabel" />
        </th>
      </tr>
    </thead>
    {pulseCheckData
      ? (
        <tbody>
          <tr>
            <td><FormattedMessage id="CompanyNameLabel" /></td>
            <td>{pulseCheckData.requester.company}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="IndustryLabel" /></td>
            <td>{pulseCheckData.requester.industry}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="PlantOrFacilityLabel" /></td>
            <td>{pulseCheckData.requester.facility}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="CountryLabel" /></td>
            <td>{pulseCheckData.requester.country}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="ZipOrPostalCodeLabel" /></td>
            <td>{pulseCheckData.requester.zipCode}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="NameLabel" /></td>
            <td>
              {pulseCheckData.requester.firstName}
              {' '}
              {pulseCheckData.requester.lastName}
            </td>
          </tr>
          <tr>
            <td><FormattedMessage id="EmailLabel" /></td>
            <td>{pulseCheckData.requester.email}</td>
          </tr>
        </tbody>
      )
      : (
        <tbody>
          <tr>
            <td><FormattedMessage id="CompanyNameLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="IndustryLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="PlantOrFacilityLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="CountryLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="ZipOrPostalCodeLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="NameLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="EmailLabel" /></td>
            <td />
          </tr>
        </tbody>
      )}
  </table>
);

CustomerInformationTable.defaultProps = {
  pulseCheckData: {
    preferredEmailContact: '',
    preferredPhoneContact: '',
    requester: {
      industry: '',
      facility: '',
      country: '',
      zipCode: '',
      firstName: '',
      lastName: '',
    },
  },
};

CustomerInformationTable.propTypes = {
  pulseCheckData: PropTypes.shape({
    preferredEmailContact: PropTypes.string,
    preferredPhoneContact: PropTypes.string,
    requester: PropTypes.shape({
      industry: PropTypes.string,
      facility: PropTypes.string,
      country: PropTypes.string,
      zipCode: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
};

export default CustomerInformationTable;

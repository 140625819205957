class StaticStrings {
  static getPulseCheckStatus = intl => ({
    Open: intl.formatMessage({ id: 'PulseCheckStatusOpen' }),
    Pending: intl.formatMessage({ id: 'PulseCheckStatusPending' }),
    Closed: intl.formatMessage({ id: 'PulseCheckStatusClosed' }),
    Cancelled: intl.formatMessage({ id: 'PulseCheckStatusCancelled' }),
  });

  static getAssetAspects = intl => ({
    Bearings: intl.formatMessage({ id: 'Recommendation-Bearings' }),
    Balance: intl.formatMessage({ id: 'Recommendation-Balance' }),
    Alignment: intl.formatMessage({ id: 'Recommendation-Alignment' }),
    Looseness: intl.formatMessage({ id: 'Recommendation-Looseness' }),
    Lubrication: intl.formatMessage({ id: 'Recommendation-Lubrication' }),
    Electrical: intl.formatMessage({ id: 'Recommendation-Electrical' }),
    Cavitation: intl.formatMessage({ id: 'Recommendation-Cavitation' }),
    Flow: intl.formatMessage({ id: 'Recommendation-Flow' }),
    Damage: intl.formatMessage({ id: 'Recommendation-Damage' }),
  });

  static getRecommendationConcepts = intl => ({
    Bearings: intl.formatMessage({ id: 'Recommendation-Bearings' }),
    Balance: intl.formatMessage({ id: 'Recommendation-Balance' }),
    Alignment: intl.formatMessage({ id: 'Recommendation-Alignment' }),
    Looseness: intl.formatMessage({ id: 'Recommendation-Looseness' }),
    Lubrication: intl.formatMessage({ id: 'Recommendation-Lubrication' }),
    Electrical: intl.formatMessage({ id: 'Recommendation-Electrical' }),
    Cavitation: intl.formatMessage({ id: 'Recommendation-Cavitation' }),
    Flow: intl.formatMessage({ id: 'Recommendation-Flow' }),
    Damage: intl.formatMessage({ id: 'Recommendation-Damage' }),
  });

  static getIndustries = intl => ({
    Metal: intl.formatMessage({ id: 'Industry-Metal' }),
    Industry: intl.formatMessage({ id: 'Industry-Industry' }),
    'Food & Beverage': intl.formatMessage({ id: 'Industry-FoodAndBeverage' }),
    Agriculture: intl.formatMessage({ id: 'Industry-Agriculture' }),
    'Oil & Gas': intl.formatMessage({ id: 'Industry-OilAndGas' }),
    'Machine Tool': intl.formatMessage({ id: 'Industry-MachineTool' }),
    Other: intl.formatMessage({ id: 'Industry-Other' }),
    'Power Generation': intl.formatMessage({ id: 'Industry-PowerGeneration' }),
    'Hydrocarbon Processing': intl.formatMessage({ id: 'Industry-HydrocarbonProcessing' }),
    'Pulp & Paper': intl.formatMessage({ id: 'Industry-PulpAndPaper' }),
    Marine: intl.formatMessage({ id: 'Industry-Marine' }),
    Construction: intl.formatMessage({ id: 'Industry-Construction' }),
    Mining: intl.formatMessage({ id: 'Industry-Mining' }),
  });

  static getApplications = intl => ({
    'Air Handling Unit': intl.formatMessage({ id: 'Application-AirHandlingUnit' }),
    Application: intl.formatMessage({ id: 'Application-Application' }),
    'Bale Aligner': intl.formatMessage({ id: 'Application-BaleAligner' }),
    Blower: intl.formatMessage({ id: 'Application-Blower' }),
    'Boring Machine': intl.formatMessage({ id: 'Application-BoringMachine' }),
    'Capping Machine': intl.formatMessage({ id: 'Application-CappingMachine' }),
    'Case Erector': intl.formatMessage({ id: 'Application-CaseErector' }),
    'Casing Running Tool': intl.formatMessage({ id: 'Application-CasingRunningTool' }),
    'Cement Truck': intl.formatMessage({ id: 'Application-CementTruck' }),
    Centrifuge: intl.formatMessage({ id: 'Application-Centrifuge' }),
    Clutch: intl.formatMessage({ id: 'Application-Clutch' }),
    'Coding Machine': intl.formatMessage({ id: 'Application-CodingMachine' }),
    Compressor: intl.formatMessage({ id: 'Application-Compressor' }),
    Conveyor: intl.formatMessage({ id: 'Application-Conveyor' }),
    'Counting Machine': intl.formatMessage({ id: 'Application-CountingMachine' }),
    Crane: intl.formatMessage({ id: 'Application-Crane' }),
    'Crusher/Grinder': intl.formatMessage({ id: 'Application-CrusherOrGrinder' }),
    Cutter: intl.formatMessage({ id: 'Application-Cutter' }),
    Debarker: intl.formatMessage({ id: 'Application-Debarker' }),
    Degerminator: intl.formatMessage({ id: 'Application-Degerminator' }),
    Detacher: intl.formatMessage({ id: 'Application-Detacher' }),
    Dozer: intl.formatMessage({ id: 'Application-Dozer' }),
    Dragline: intl.formatMessage({ id: 'Application-Dragline' }),
    Drawworks: intl.formatMessage({ id: 'Application-Drawworks' }),
    'Drilling Stabilizer': intl.formatMessage({ id: 'Application-DrillingStabilizer' }),
    Drive: intl.formatMessage({ id: 'Application-Drive' }),
    'Drill Line Storage Reel': intl.formatMessage({ id: 'Application-DrillLineStorageReel' }),
    'Drill Line Storage Rool': intl.formatMessage({ id: 'Application-DrillLineStorageRool' }),
    'Drill Press': intl.formatMessage({ id: 'Application-DrillPress' }),
    Dryer: intl.formatMessage({ id: 'Application-Dryer' }),
    'Dumper Truck': intl.formatMessage({ id: 'Application-DumperTruck' }),
    Elevator: intl.formatMessage({ id: 'Application-Elevator' }),
    Excavator: intl.formatMessage({ id: 'Application-Excavator' }),
    Extruder: intl.formatMessage({ id: 'Application-Extruder' }),
    Fan: intl.formatMessage({ id: 'Application-Fan' }),
    Feeder: intl.formatMessage({ id: 'Application-Feeder' }),
    'Filling Machine': intl.formatMessage({ id: 'Application-FillingMachine' }),
    Fourdrinier: intl.formatMessage({ id: 'Application-Fourdrinier' }),
    'Fuel Truck': intl.formatMessage({ id: 'Application-FuelTruck' }),
    Generator: intl.formatMessage({ id: 'Application-Generator' }),
    Grader: intl.formatMessage({ id: 'Application-Grader' }),
    Granulator: intl.formatMessage({ id: 'Application-Granulator' }),
    Grinder: intl.formatMessage({ id: 'Application-Grinder' }),
    Kiln: intl.formatMessage({ id: 'Application-Kiln' }),
    Lathe: intl.formatMessage({ id: 'Application-Lathe' }),
    Layboy: intl.formatMessage({ id: 'Application-Layboy' }),
    'Log Separator': intl.formatMessage({ id: 'Application-LogSeparator' }),
    'Haul Truck': intl.formatMessage({ id: 'Application-HaulTruck' }),
    Hoist: intl.formatMessage({ id: 'Application-Hoist' }),
    'Lifting Machine': intl.formatMessage({ id: 'Application-LiftingMachine' }),
    'Lifting Table': intl.formatMessage({ id: 'Application-LiftingTable' }),
    Loader: intl.formatMessage({ id: 'Application-Loader' }),
    'Loading Arm': intl.formatMessage({ id: 'Application-LoadingArm' }),
    Mill: intl.formatMessage({ id: 'Application-Mill' }),
    'Milling Machine': intl.formatMessage({ id: 'Application-MillingMachine' }),
    'Mixer/Agitator': intl.formatMessage({ id: 'Application-MixerOrAgitator' }),
    'Mobile Equipment': intl.formatMessage({ id: 'Application-MobileEquipment' }),
    Monorail: intl.formatMessage({ id: 'Application-Monorail' }),
    'Mud Cleaner': intl.formatMessage({ id: 'Application-MudCleaner' }),
    'Oil & Gas': intl.formatMessage({ id: 'Application-OilAndGas' }),
    'Oscillating Shower': intl.formatMessage({ id: 'Application-OscillatingShower' }),
    Other: intl.formatMessage({ id: 'Application-Other' }),
    Packer: intl.formatMessage({ id: 'Application-Packer' }),
    'Packing Machine': intl.formatMessage({ id: 'Application-PackingMachine' }),
    'Packaging Machine': intl.formatMessage({ id: 'Application-PackagingMachine' }),
    Palletizer: intl.formatMessage({ id: 'Application-Palletizer' }),
    Peeler: intl.formatMessage({ id: 'Application-Peeler' }),
    'Power Swivel': intl.formatMessage({ id: 'Applicaiton-PowerSwivel' }),
    'Pre-breaker': intl.formatMessage({ id: 'Application-PreBreaker' }),
    Press: intl.formatMessage({ id: 'Application-Press' }),
    Propeller: intl.formatMessage({ id: 'Application-Propeller' }),
    'Press Machine': intl.formatMessage({ id: 'Application-PressMachine' }),
    Pulper: intl.formatMessage({ id: 'Application-Pulper' }),
    Pulverizer: intl.formatMessage({ id: 'Application-Pulverizer' }),
    Pump: intl.formatMessage({ id: 'Application-Pump' }),
    'Recirculation System': intl.formatMessage({ id: 'Application-RecirculationSystem' }),
    Reclaimer: intl.formatMessage({ id: 'Application-Reclaimer' }),
    Reel: intl.formatMessage({ id: 'Application-Reel' }),
    Repulper: intl.formatMessage({ id: 'Application-Repulper' }),
    Roll: intl.formatMessage({ id: 'Application-Roll' }),
    Roller: intl.formatMessage({ id: 'Application-Roller' }),
    'Rotary Table': intl.formatMessage({ id: 'Application-RotaryTable' }),
    Saw: intl.formatMessage({ id: 'Application-Saw' }),
    'Sealing Machine': intl.formatMessage({ id: 'Application-SealingMachine' }),
    'Seamer Machine': intl.formatMessage({ id: 'Application-SeamerMachine' }),
    Screener: intl.formatMessage({ id: 'Application-Screener' }),
    Stacker: intl.formatMessage({ id: 'Application-Stacker' }),
    Strapper: intl.formatMessage({ id: 'Application-Strapper' }),
    Shaker: intl.formatMessage({ id: 'Application-Shaker' }),
    Shovel: intl.formatMessage({ id: 'Application-Shovel' }),
    Sieve: intl.formatMessage({ id: 'Application-Sieve' }),
    Skimmer: intl.formatMessage({ id: 'Application-Skimmer' }),
    'Tamping Machine': intl.formatMessage({ id: 'Application-TampingMachine' }),
    Thruster: intl.formatMessage({ id: 'Application-Thruster' }),
    'Tote Tilt': intl.formatMessage({ id: 'Application-ToteTilt' }),
    'Torque Converter': intl.formatMessage({ id: 'Application-TorqueConverter' }),
    Transportation: intl.formatMessage({ id: 'Application-Transportation' }),
    Tumbler: intl.formatMessage({ id: 'Application-Tumbler' }),
    Trolley: intl.formatMessage({ id: 'Application-Trolley' }),
    Turbine: intl.formatMessage({ id: 'Application-Turbine' }),
    Turbocharger: intl.formatMessage({ id: 'Application-Turbocharger' }),
    Underreamer: intl.formatMessage({ id: 'Application-Underreamer' }),
    Vibrator: intl.formatMessage({ id: 'Application-Vibrator' }),
    'Water Truck': intl.formatMessage({ id: 'Application-WaterTruck' }),
    'Wrapping Machine': intl.formatMessage({ id: 'Application-WrappingMachine' }),
    Wrapper: intl.formatMessage({ id: 'Application-Wrapper' }),
    'Wire Tying Machine': intl.formatMessage({ id: 'Application-WireTyingMachine' }),
  });

  static getAnalystNotAssigned = intl => ({
    'Not Assigned': intl.formatMessage({ id: 'AnalystNotAssignedLabel' }),
  });

  static getAssetTypes = intl => ({
    Compressor: intl.formatMessage({ id: 'AssetType-Compressor' }),
    Fan: intl.formatMessage({ id: 'AssetType-Fan' }),
    Gearbox: intl.formatMessage({ id: 'AssetType-Gearbox' }),
    Motor: intl.formatMessage({ id: 'AssetType-Motor' }),
    Pump: intl.formatMessage({ id: 'AssetType-Pump' }),
    Shaft: intl.formatMessage({ id: 'AssetType-Shaft' }),
    'Rotary Lobe': intl.formatMessage({ id: 'AssetType-RotaryLobe' }),
    Screw: intl.formatMessage({ id: 'AssetType-Screw' }),
    Vane: intl.formatMessage({ id: 'AssetType-Vane' }),
    'Centrifugal Central Impeller': intl.formatMessage({ id: 'AssetType-CentrifugalCentralImpeller' }),
    'Centrifugal Overhung': intl.formatMessage({ id: 'AssetType-CentrifugalOverhung' }),
    '2 Stage 90 Degree Bevel First Stage': intl.formatMessage({ id: 'AssetType-2Stage90DegreeBevelFirstStage' }),
    '2 Stage Parallel': intl.formatMessage({ id: 'AssetType-2StageParallel' }),
    '3 Stage 90 Degree Bevel First Stage': intl.formatMessage({ id: 'AssetType-3Stage90DegreeBevelFirstStage' }),
    '3 Stage Parallel': intl.formatMessage({ id: 'AssetType-3StageParallel' }),
    '4 Stage 90 Degree Bevel First Stage': intl.formatMessage({ id: 'AssetType-4Stage90DegreeBevelFirstStage' }),
    '4 Stage Parallel': intl.formatMessage({ id: 'AssetType-4StageParallel' }),
    'Single Stage 90 Degree Bevel': intl.formatMessage({ id: 'AssetType-SingleStage90DegreeBevel' }),
    'Single Stage Parallel': intl.formatMessage({ id: 'AssetType-SingleStageParallel' }),
    'Worm Drive': intl.formatMessage({ id: 'AssetType-WormDrive' }),
    Electric: intl.formatMessage({ id: 'AssetType-Electric' }),
    'Central Impeller': intl.formatMessage({ id: 'AssetType-CentralImpeller' }),
    'Electric Motor Attached': intl.formatMessage({ id: 'AssetType-ElectricMotorAttached' }),
    'Gear Central Impeller': intl.formatMessage({ id: 'AssetType-GearCentralImpeller' }),
    Overhung: intl.formatMessage({ id: 'AssetType-Overhung' }),
    Cardon: intl.formatMessage({ id: 'AssetType-Cardon' }),
    'Speed Increaser': intl.formatMessage({ id: 'AssetType-SpeedIncreaser' }),
    'Speed Reducer': intl.formatMessage({ id: 'AssetType-SpeedReducer' }),
    'Compressor-Rotary Lobe': intl.formatMessage({ id: 'AssetType-Compressor-RotaryLobe' }),
    'Compressor-Screw': intl.formatMessage({ id: 'AssetType-Compressor-Screw' }),
    'Compressor-Vane': intl.formatMessage({ id: 'AssetType-Compressor-Vane' }),
    'Fan-Centrifugal Central Impeller': intl.formatMessage({ id: 'AssetType-Fan-CentrifugalCentralImpeller' }),
    'Fan-Centrifugal Overhung': intl.formatMessage({ id: 'AssetType-Fan-CentrifugalOverhung' }),
    'Gearbox-2 Stage 90 Degree Bevel First Stage Speed Increaser': intl.formatMessage({ id: 'AssetType-Gearbox-2Stage90DegreeBevelFirstStageSpeedIncreaser' }),
    'Gearbox-2 Stage 90 Degree Bevel First Stage Speed Reducer': intl.formatMessage({ id: 'AssetType-Gearbox-2Stage90DegreeBevelFirstStageSpeedReducer' }),
    'Gearbox-2 Stage Parallel Speed Increaser': intl.formatMessage({ id: 'AssetType-Gearbox-2StageParallelSpeedIncreaser' }),
    'Gearbox-2 Stage Parallel Speed Reducer': intl.formatMessage({ id: 'AssetType-Gearbox-2StageParallelSpeedReducer' }),
    'Gearbox-3 Stage 90 Degree Bevel First Stage': intl.formatMessage({ id: 'AssetType-Gearbox-3Stage90DegreeBevelFirstStage' }),
    'Gearbox-3 Stage Parallel': intl.formatMessage({ id: 'AssetType-Gearbox-3StageParallel' }),
    'Gearbox-4 Stage 90 Degree Bevel First Stage': intl.formatMessage({ id: 'AssetType-Gearbox-4Stage90DegreeBevelFirstStage' }),
    'Gearbox-4 Stage Parallel': intl.formatMessage({ id: 'AssetType-Gearbox-4StageParallel' }),
    'Gearbox-Single Stage 90 Degree Bevel Speed Increaser': intl.formatMessage({ id: 'AssetType-Gearbox-SingleStage90DegreeBevelSpeedIncreaser' }),
    'Gearbox-Single Stage 90 Degree Bevel Speed Reducer': intl.formatMessage({ id: 'AssetType-Gearbox-SingleStage90DegreeBevelSpeedReducer' }),
    'Gearbox-Single Stage Parallel Speed Increaser': intl.formatMessage({ id: 'AssetType-Gearbox-SingleStageParallelSpeedIncreaser' }),
    'Gearbox-Single Stage Parallel Speed Reducer': intl.formatMessage({ id: 'AssetType-Gearbox-SingleStageParallelSpeedReducer' }),
    'Gearbox-Worm Drive': intl.formatMessage({ id: 'AssetType-Gearbox-WormDrive' }),
    'Motor-Electric': intl.formatMessage({ id: 'AssetType-Motor-Electric' }),
    'Pump-Central Impeller': intl.formatMessage({ id: 'AssetType-Pump-CentralImpeller' }),
    'Pump-Electric Motor Attached': intl.formatMessage({ id: 'AssetType-Pump-ElectricMotorAttached' }),
    'Pump-Gear Central Impeller': intl.formatMessage({ id: 'AssetType-Pump-GearCentralImpeller' }),
    'Pump-Overhung': intl.formatMessage({ id: 'AssetType-Pump-Overhung' }),
    'Shaft-Cardon': intl.formatMessage({ id: 'AssetType-Shaft-Cardon' }),
    'Shaft-Two Bearing Housing': intl.formatMessage({ id: 'AssetType-Shaft-TwoBearingHousing' }),
    'Two Bearing Housing': intl.formatMessage({ id: 'AssetType-TwoBearingHousing' }),
  });
}

export default StaticStrings;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const PulseCheckTitle = ({ pulseCheckData, cancelled }) => (
  <div className="Page-two-title">
    <h3>
      <FormattedMessage
        id="PulseCheckForCompanyAndCountryText"
        values={{
          company: pulseCheckData.requester.company,
          country: pulseCheckData.requester.country,
        }}
      />
    </h3>
    <p>
      <FormattedMessage
        id="PulseCheckIdText"
        values={{ id: pulseCheckData.id }}
      />
    </p>
    <p>
      <FormattedMessage
        id="PulseCheckRequiredDateText"
        values={{
          requestedTime: moment(new Date(pulseCheckData.requestedDate)).format('hh:mm a'),
          timezone: moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr(),
          requestedDate: moment(new Date(pulseCheckData.requestedDate)).format('l'),
        }}
      />
    </p>
    {cancelled ? (<Alert color="danger"><FormattedMessage id="PulseCheckCanceledMessage" /></Alert>) : <span />}
    {pulseCheckData.requiresEmailResponse ? (
      <Alert color="warning">
        <FormattedMessage id="PulseCheckRequiresEmailResponseMessage" />
        {' '}
        <a href={`mailto:${pulseCheckData.requester.email}`}>
          {pulseCheckData.requester.email}
        </a>
      </Alert>) : <span />}
  </div>
);

PulseCheckTitle.defaultProps = {
  cancelled: false,
  profileData: {
    email: '',
    company: '',
    country: '',
  },
  pulseCheckData: {
    requestedDate: '',
  },
};

PulseCheckTitle.propTypes = {
  cancelled: PropTypes.bool,
  profileData: PropTypes.shape({
    email: PropTypes.string,
    company: PropTypes.string,
    country: PropTypes.string,
  }),
  pulseCheckData: PropTypes.shape({
    requiresEmailResponse: PropTypes.bool,
    requestedDate: PropTypes.string,
    requester: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
};

export default PulseCheckTitle;

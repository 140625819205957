import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const sortedData = {};
const TemperatureDataTable = ({ measurementData }) => (
  <table className="Page-two-table Temperature-data">
    {measurementData.measurements.length > 0
      ? (
        <tbody>
          {Object.keys(measurementData.measurements[0].measurementPoints)
            .sort((a, b) => a.localeCompare(b)).forEach((key) => {
              sortedData[key] = measurementData.measurements[0].measurementPoints[key];
            })
          }
          <tr>
            <th colSpan="3" className="Table-title">
              <FormattedMessage id="TemperatureDataLabel" />
            </th>
          </tr>
          <tr>
            <td><FormattedMessage id="DateLabel" /></td>
            <td colSpan="2" id="Temperature-date">{`${moment(measurementData.measurements[0].measurementEndTime).format('HH:mm')}  ${moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr()} ${moment(measurementData.measurements[0].measurementEndTime).format('YYYY-MM-DD')}`}</td>
          </tr>
          {Object.entries(sortedData)
            .map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td>{value.temperature ? Number.parseFloat(value.temperature).toPrecision(2) : 'N/A'}</td>
                <td><FormattedMessage id="CelsiusGradesLabel" /></td>
              </tr>
            ))}
        </tbody>
      )
      : (
        <tbody>
          <tr>
            <th colSpan="3" className="Table-title">
              <FormattedMessage id="TemperatureDataLabel" />
            </th>
          </tr>
          <tr>
            <td><FormattedMessage id="NotApplicableShortLabel" /></td>
            <td><FormattedMessage id="NotApplicableShortLabel" /></td>
            <td><FormattedMessage id="CelsiusGradesLabel" /></td>
          </tr>
        </tbody>
      )
    }
  </table>
);

TemperatureDataTable.defaultProps = {
  measurementData: {},
};

TemperatureDataTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  measurementData: PropTypes.any,
};

export default TemperatureDataTable;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import Alert from './Alerts';
import authService from '../../utils/auth-service';
import config from '../../config';

class ClosePulseCheck extends Component {
  constructor(props) {
    super(props);

    const t = this;

    this.toggle = this.toggle.bind(this);

    t.state = {
      status: t.props.status,
      pulseCheckId: t.props.pulseCheckId,
      closeFailed: null,
      cancelled: t.props.cancelled,
      modal: false,
    };
  }

  componentDidMount() {
    const t = this;
    if (t.state.status === 'Closed') {
      t.props.onClose(true);
    }
  }

  handleCloseBtnClick(pulseCheckId) {
    const pulseCheckLoginData = authService.getAuthInfo();
    const pulseCheckAssignUrl = `api/pulsechecks/${pulseCheckId}/Close`;
    const pulseCheckApiUrl = new URL(pulseCheckAssignUrl, config.API_URL);
    const t = this;

    return Axios.post(pulseCheckApiUrl, {}, { headers: { Authorization: `Bearer ${pulseCheckLoginData.accessToken}` } })
      .then(() => {
        this.setState({
          status: 'Closed',
          closeFailed: false,
        });
        t.props.onClose(true);
        t.toggle();
      }).catch(() => {
        this.setState({
          closeFailed: true,
        });
        t.toggle();
      });
  }

  toggle() {
    const t = this;
    if (!t.props.pulseCheckIsComplete) {
      this.setState({
        closeFailed: true,
      });
    } else {
      t.setState(prevState => ({
        modal: !prevState.modal,
      }));
    }
  }

  render() {
    const {
      status, pulseCheckId, closeFailed, modal,
    } = this.state;
    const { hasAnalyst, cancelled } = this.props;
    return (
      <div>
        {
          (hasAnalyst && status !== 'Closed' && status !== 'Cancelled' && !cancelled)
            ? (
              <>
                <Button outline color="primary" onClick={this.toggle}>
                  <FormattedMessage id="ClosePulseCheckLabel" />
                </Button>
                <Modal isOpen={modal} toggle={this.toggle}>
                  <ModalHeader toggle={this.toggle}>
                    <FormattedMessage id="ClosePulseCheckLabel" />
                  </ModalHeader>
                  <ModalBody>
                    <FormattedMessage id="ClosePulseCheckQuestion" />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>
                      <FormattedMessage id="GoBackLabel" />
                    </Button>
                    <Button color="secondary" onClick={() => this.handleCloseBtnClick(pulseCheckId)}>
                      <FormattedMessage id="ClosePulseCheckLabel" />
                    </Button>
                  </ModalFooter>
                </Modal>
              </>
            )
            : (
              <Button disabled color="secondary">
                <FormattedMessage id="ClosePulseCheckLabel" />
              </Button>
            )
        }
        {
          // eslint-disable-next-line no-nested-ternary
          closeFailed === null
            ? <div />
            : closeFailed ? <Alert.CloseFailed /> : <Alert.CloseSucceed />
        }
      </div>
    );
  }
}

ClosePulseCheck.defaultProps = {
  hasAnalyst: false,
  cancelled: false,
};

ClosePulseCheck.propTypes = {
  hasAnalyst: PropTypes.bool,
  cancelled: PropTypes.bool,
};

export default ClosePulseCheck;

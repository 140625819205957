import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const CustomerCommentTable = ({ pulseCheckData }) => (
  <table className="Page-two-table">
    <thead>
      <tr>
        <th className="Table-title">
          <FormattedMessage id="CommentLabel" />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr><td>{(pulseCheckData && pulseCheckData.comment) || (<span>&nbsp;</span>)}</td></tr>
    </tbody>
  </table>
);

CustomerCommentTable.defaultProps = {
  pulseCheckData: {
    comment: undefined,
  },
};
CustomerCommentTable.propTypes = {
  pulseCheckData: PropTypes.shape({
    comment: PropTypes.string,
  }),
};

export default CustomerCommentTable;

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import DisplayRecommendation from './DisplayRecommendation';

class Recommendations extends Component {
  static getIcon(statusColor) {
    switch (statusColor) {
      case 'Green':
        return 'fa fa-check-circle fa-2x recommendation-icon';
      case 'Yellow':
        return 'fa fa-minus-circle fa-2x recommendation-icon';
      case 'Red':
        return 'fa fa-exclamation-circle fa-2x recommendation-icon';
      default:
        return '';
    }
  }

  constructor(props) {
    super(props);

    const t = this;
    t.state = {
      data: null,
    };
  }

  componentDidUpdate(newProps) {
    const t = this;
    if (newProps !== t.state.data) {
      t.setState({
        data: newProps,
      });
    }
  }

  setRecommendations(displayName) {
    const { data } = this.state;
    const [advertisement] = data.advertisements.filter(a => a.category === displayName);
    const [status] = data.statuses.filter(s => s.heading === displayName);

    return (
      <div key={displayName}>
        <div className="Recommendation-title">
          {status ? <i className={Recommendations.getIcon(status.statusColor)} aria-hidden="true" /> : ''}
          {DisplayRecommendation.FindDisplayText(displayName, advertisement)}
        </div>
      </div>
    );
  }

  render() {
    const t = this;
    return (
      <div>
        <div className="Report-page-title">
          <h4><FormattedMessage id="RecommendationsLabel" /></h4>
          <div className="Report-line" />
        </div>
        { t.state.data !== null && t.state.data.recommendationData !== undefined
          && t.state.data.recommendationData !== null && t.state.data.advertisements !== undefined
          ? (
            <div className="Report-recommendations">
              {Object.keys(t.state.data.recommendationData).map(key => (
                this.setRecommendations(key)
              ))}
            </div>
          )
          : <div />
        }
      </div>
    );
  }
}
export default Recommendations;

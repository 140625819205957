import { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import authService from '../../utils/auth-service';

class TokenInterceptor extends Component {
  constructor() {
    super();
    axios.interceptors.request.use(
      (_config) => {
        const authInfo = authService.getAuthInfo();
        if (_config.headers.Authorization && authInfo
            && !authService.isAuthenticated()) {
          authService.refreshToken();
        }
        return _config;
      },
      error => Promise.reject(error),
    );

    axios.interceptors.response.use(
      _response => _response,
      (error) => {
        const originalRequest = error.config;
        if (error.response.status !== 401
            || !originalRequest.headers.Authorization
            // eslint-disable-next-line no-underscore-dangle
            || originalRequest._retry) {
          return Promise.reject(error);
        }

        // eslint-disable-next-line no-underscore-dangle
        originalRequest._retry = true;
        return authService.refreshToken()
          .then(() => {
            const authInfo = authService.getAuthInfo();
            // eslint-disable-next-line no-param-reassign
            error.config.headers.Authorization = `Bearer ${authInfo.accessToken}`;
            return axios(originalRequest);
          })
          .catch(() => {
            const { onRefreshTokenFailure } = this.props;
            if (onRefreshTokenFailure) {
              onRefreshTokenFailure();
            }
            return Promise.reject();
          });
      },
    );
  }

  render() { return (null); }
}

TokenInterceptor.propTypes = {
  onRefreshTokenFailure: PropTypes.func.isRequired,
};

export default TokenInterceptor;

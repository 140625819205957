import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ActiveThresholdsTable = ({ pulseCheckData }) => (
  <table className="Page-two-table" id="Active-threshold-table">
    {pulseCheckData && pulseCheckData.asset.usingCustomThresholds && (
      <tbody>
        <tr>
          <th colSpan="4" className="Table-title">
            <FormattedMessage id="ActiveThresholdsLabel" />
          </th>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title" />
          <td className="Threshold-vertical-table-td">
            <FormattedMessage id="ThresholdAlertLabel" />
          </td>
          <td className="Threshold-vertical-table-td">
            <FormattedMessage id="ThresholdDangerLabel" />
          </td>
          <td>
            <FormattedMessage id="UnitsLabel" />
          </td>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title">
            <FormattedMessage id="VelocityLabel" />
          </td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.asset.thresholds.Velocity ? Number.parseFloat(pulseCheckData.asset.thresholds.Velocity.highAlertLevel).toFixed(3) : ''}</td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.asset.thresholds.Velocity ? Number.parseFloat(pulseCheckData.asset.thresholds.Velocity.highDangerLevel).toFixed(3) : ''}</td>
          <td><FormattedMessage id="RmsUnitLabel" /></td>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title">
            <FormattedMessage id="AccelerationEnvelopingLabel" />
          </td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.asset.thresholds.Acceleration ? Number.parseFloat(pulseCheckData.asset.thresholds.Acceleration.highAlertLevel).toFixed(3) : ''}</td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.asset.thresholds.Acceleration ? Number.parseFloat(pulseCheckData.asset.thresholds.Acceleration.highDangerLevel).toFixed(3) : ''}</td>
          <td><FormattedMessage id="AccelerationEnvelopingPeakToPeakLabel" /></td>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title">
            <FormattedMessage id="TemperatureLabel" />
          </td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.asset.thresholds.Temperature ? Number.parseFloat(pulseCheckData.asset.thresholds.Temperature.highAlertLevel).toFixed(3) : ''}</td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.asset.thresholds.Temperature ? Number.parseFloat(pulseCheckData.asset.thresholds.Temperature.highDangerLevel).toFixed(3) : ''}</td>
          <td><FormattedMessage id="CelsiusGradesLabel" /></td>
        </tr>
      </tbody>
    )
  }
    {pulseCheckData && pulseCheckData.asset && !pulseCheckData.asset.usingCustomThresholds && (
      <tbody>
        <tr>
          <th colSpan="4" className="Table-title">
            <FormattedMessage id="ActiveThresholdsLabel" />
          </th>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title" />
          <td className="Threshold-vertical-table-td">
            <FormattedMessage id="ThresholdAlertLabel" />
          </td>
          <td className="Threshold-vertical-table-td">
            <FormattedMessage id="ThresholdDangerLabel" />
          </td>
          <td>
            <FormattedMessage id="UnitsLabel" />
          </td>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title">
            <FormattedMessage id="VelocityLabel" />
          </td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.effectiveThresholds.Velocity ? Number.parseFloat(pulseCheckData.effectiveThresholds.Velocity.highAlertLevel).toFixed(3) : ''}</td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.effectiveThresholds.Velocity ? Number.parseFloat(pulseCheckData.effectiveThresholds.Velocity.highDangerLevel).toFixed(3) : ''}</td>
          <td><FormattedMessage id="RmsUnitLabel" /></td>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title">
            <FormattedMessage id="AccelerationEnvelopingLabel" />
          </td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.effectiveThresholds.Acceleration ? Number.parseFloat(pulseCheckData.effectiveThresholds.Acceleration.highAlertLevel).toFixed(3) : ''}</td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.effectiveThresholds.Acceleration ? Number.parseFloat(pulseCheckData.effectiveThresholds.Acceleration.highDangerLevel).toFixed(3) : ''}</td>
          <td><FormattedMessage id="AccelerationEnvelopingPeakToPeakLabel" /></td>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title">
            <FormattedMessage id="TemperatureLabel" />
          </td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.effectiveThresholds.Temperature ? Number.parseFloat(pulseCheckData.effectiveThresholds.Temperature.highAlertLevel).toFixed(3) : ''}</td>
          <td className="Threshold-vertical-table-td">{pulseCheckData.effectiveThresholds.Temperature ? Number.parseFloat(pulseCheckData.effectiveThresholds.Temperature.highDangerLevel).toFixed(3) : ''}</td>
          <td><FormattedMessage id="CelsiusGradesLabel" /></td>
        </tr>
      </tbody>
    )
  }
    {(!pulseCheckData || !pulseCheckData.asset) && (
      <tbody>
        <tr>
          <th colSpan="4" className="Table-title">
            <FormattedMessage id="ActiveThresholdsLabel" />
          </th>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title" />
          <td className="Threshold-vertical-table-td">
            <FormattedMessage id="ThresholdAlertLabel" />
          </td>
          <td className="Threshold-vertical-table-td">
            <FormattedMessage id="ThresholdDangerLabel" />
          </td>
          <td>
            <FormattedMessage id="UnitsLabel" />
          </td>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title">
            <FormattedMessage id="VelocityLabel" />
          </td>
          <td className="Threshold-vertical-table-td" />
          <td className="Threshold-vertical-table-td" />
          <td><FormattedMessage id="RmsUnitLabel" /></td>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title">
            <FormattedMessage id="AccelerationEnvelopingLabel" />
          </td>
          <td className="Threshold-vertical-table-td" />
          <td className="Threshold-vertical-table-td" />
          <td><FormattedMessage id="AccelerationEnvelopingPeakToPeakLabel" /></td>
        </tr>
        <tr>
          <td className="Threshold-vertical-table-title">
            <FormattedMessage id="TemperatureLabel" />
          </td>
          <td className="Threshold-vertical-table-td" />
          <td className="Threshold-vertical-table-td" />
          <td><FormattedMessage id="CelsiusGradesLabel" /></td>
        </tr>
      </tbody>
    )
  }
  </table>
);

ActiveThresholdsTable.defaultProps = {
  pulseCheckData: {},
};

ActiveThresholdsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pulseCheckData: PropTypes.any,
};

export default ActiveThresholdsTable;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const DownloadData = ({ handleDownloadDataButton, downloadDataButtonText, downloading }) => (
  <Button color="primary" disabled={downloading} onClick={handleDownloadDataButton}>{downloadDataButtonText}</Button>
);

DownloadData.propTypes = {
  handleDownloadDataButton: PropTypes.func.isRequired,
  downloadDataButtonText: PropTypes.string.isRequired,
  downloading: PropTypes.bool.isRequired,
};

export default DownloadData;

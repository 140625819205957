import React from 'react';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const SaveFailed = (error) => {
  let textId = '';
  switch (error.errorCode) {
    case 'ProfileMissingPermissionException':
      textId = 'ProfileMissingPermissionAlertMessage';
      break;
    case 'ValidationFailedException':
      textId = 'SaveFailedAlertMessage';
      break;
    case 'IncompleteAssetStatusesException':
      textId = 'SaveFailedAlertMessage';
      break;
    default:
      textId = 'SaveFailedGeneralMessage';
  }
  return <Alert color="danger"><FormattedMessage id={textId} /></Alert>;
};

const SummarySaveFailed = () => (
  <Alert color="danger"><FormattedMessage id="SummarySaveFailedAlertMessage" /></Alert>
);

const SummaryMissing = () => (
  <Alert color="danger"><FormattedMessage id="SummaryMissingAlertMessage" /></Alert>
);

const CloseFailed = () => (
  <Alert color="danger" id="Close-alert-div-failed"><FormattedMessage id="CloseFailedAlertMessage" /></Alert>
);

const CloseSucceed = () => (
  <Alert color="success" id="Close-alert-div-success"><FormattedMessage id="CloseSucceedAlertMessage" /></Alert>
);

const CancelFailed = () => (
  <Alert color="danger" id="Cancel-alert-div-failed"><FormattedMessage id="CancelFailedAlertMessage" /></Alert>
);

const CancelSucceed = () => (
  <Alert color="success" id="Close-alert-div-success"><FormattedMessage id="CancelSucceedAlertMessage" /></Alert>
);

const EmptyReport = () => (
  <Alert color="danger" id="Empty-report-alert-div"><FormattedMessage id="EmptyReportAlertMessage" /></Alert>
);

export default {
  SaveFailed,
  SummarySaveFailed,
  CloseFailed,
  CloseSucceed,
  CancelFailed,
  CancelSucceed,
  EmptyReport,
  SummaryMissing,
};

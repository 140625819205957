import React from 'react';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const LoginFailed = () => (
  <Alert color="danger">
    <FormattedMessage id="UsernameOrPasswordIsIncorrectMessage" />
  </Alert>
);

export default LoginFailed;

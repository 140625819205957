import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';


const SensorInformationTable = ({ sensorData }) => (
  <table className="Page-two-table">
    {sensorData
      ? (
        <tbody>
          <tr>
            <th colSpan="2" className="Table-title">
              <FormattedMessage id="SensorInformationLabel" />
            </th>
          </tr>
          <tr>
            <td><FormattedMessage id="TypeLabel" /></td>
            <td>{sensorData.type}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="ModelNumberLabel" /></td>
            <td>{sensorData.model}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="SerialNumberLabel" /></td>
            <td>{sensorData.serialNumber}</td>
          </tr>
        </tbody>
      )
      : (
        <tbody>
          <tr>
            <th colSpan="2" className="Table-title">
              <FormattedMessage id="SensorInformationLabel" />
            </th>
          </tr>
          <tr>
            <td><FormattedMessage id="TypeLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="ModelNumberLabel" /></td>
            <td />
          </tr>
          <tr>
            <td><FormattedMessage id="SerialNumberLabel" /></td>
            <td />
          </tr>
        </tbody>
      )
    }
  </table>
);

SensorInformationTable.defaultProps = {
  sensorData: {},
};

SensorInformationTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sensorData: PropTypes.any,
};

export default SensorInformationTable;

import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { useIntl } from 'react-intl';

import authService from '../../../utils/auth-service';
import config from '../../../config';

function usePulseCheckTable() {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  // eslint-disable-next-line
  const [url, setUrl] = useState(window.location.href);
  let searchableUrl = new URL(url);
  const [sort, setSort] = useState({
    category: searchableUrl.searchParams.get('sortField') !== null && searchableUrl.searchParams.get('sortField') !== 'null' ? searchableUrl.searchParams.get('sortField') : 'Id',
    value: searchableUrl.searchParams.get('sortBy') !== null && searchableUrl.searchParams.get('sortBy') !== 'null' ? searchableUrl.searchParams.get('sortBy') : 'desc',
  });
  const [filters, setFilters] = useState({
    analyst: searchableUrl.searchParams.get('analyst') !== 'null' ? searchableUrl.searchParams.get('analyst') : null,
    application: searchableUrl.searchParams.get('application') !== 'null' ? searchableUrl.searchParams.get('application') : null,
    company: searchableUrl.searchParams.get('company') !== 'null' ? searchableUrl.searchParams.get('company') : null,
    count: searchableUrl.searchParams.get('count') !== null && searchableUrl.searchParams.get('count') !== 'null' ? searchableUrl.searchParams.get('count') : 10,
    country: searchableUrl.searchParams.get('country') !== 'null' ? searchableUrl.searchParams.get('country') : null,
    industry: searchableUrl.searchParams.get('industry') !== 'null' ? searchableUrl.searchParams.get('industry') : null,
    status: searchableUrl.searchParams.get('status') !== 'null' ? searchableUrl.searchParams.get('status') : null,
    region: searchableUrl.searchParams.get('region') !== 'null' ? searchableUrl.searchParams.get('region') : null,
  });
  const [pulseChecks, setPulseChecks] = useState([]);
  const [paging, setPaging] = useState({
    page: searchableUrl.searchParams.get('page') !== null && searchableUrl.searchParams.get('page') !== 'null' ? searchableUrl.searchParams.get('page') : 1,
    totalSize: 0,
    sizePerPage: filters.count,
    maxPages: 1,
  });
  const [filteringOptions, setFilteringOptions] = useState({
    analysts: {},
    companies: {},
    countries: {},
    regions: {},
  });

  useEffect(() => {
    if (history.action === 'POP' && searchableUrl.search !== location.search) {
      const searchParams = new URLSearchParams(history.location.search);
      const newFilters = {};
      const areFilters = ['analyst', 'company', 'industry', 'application', 'country', 'status', 'count', 'region'];

      areFilters.forEach((filterCategory) => {
        newFilters[filterCategory] = searchParams.get(filterCategory) || null;
      });
      setPaging({ ...paging, page: searchParams.get('page') });
      setSort({ category: searchParams.get('sortField'), value: searchParams.get('sortBy') });
      setFilters(newFilters);
    }
  }, [history.action, history.location.search]);

  useEffect(() => {
    searchableUrl = new URL(url);
    const params = Array.from(searchableUrl.searchParams);
    params.forEach(param => searchableUrl.searchParams.delete(param[0]));
    searchableUrl.searchParams.set('page', paging.page);
    searchableUrl.searchParams.set('sortField', sort.category);
    searchableUrl.searchParams.set('sortBy', sort.value);

    const loginData = authService.getAuthInfo();
    if (url.includes('login') && !url.includes('?') && loginData.profileModel.region) {
      searchableUrl.searchParams.set('region', loginData.profileModel.region);
      filters.region = loginData.profileModel.region;
    }

    Object.keys(filters)
      .forEach((key) => {
        if (filters[key] !== null) {
          searchableUrl.searchParams.set(key, filters[key]);
        }
      });
    searchableUrl.searchParams.sort();
    setUrl(searchableUrl.href);
  }, [filters, sort, paging.page]);

  useEffect(() => {
    searchableUrl = new URL(url);
    if (searchableUrl.search === location.search || !searchableUrl.search) return;
    history.push(`queue${searchableUrl.search}`);
  }, [url]);

  useEffect(() => {
    if (!searchableUrl.search) return;
    const loginData = authService.getAuthInfo();
    // eslint-disable-next-line no-undef
    const pulseCheckQueueUrl = `api/pulsechecks/queue${history.location.search}`;
    const apiUrl = new URL(pulseCheckQueueUrl, config.API_URL);
    Axios.get(apiUrl, { headers: { Authorization: `Bearer  ${loginData.accessToken}` } })
      .then((response) => {
        if (response.data.pulseCheckList !== null) {
          setPulseChecks(response.data.pulseCheckList);
          setPaging({
            ...paging,
            totalSize: response.data.totalPulseChecks,
            sizePerPage: response.data.count,
            maxPages: Math.ceil(response.data.totalPulseChecks / response.data.count),
          });
          setFilteringOptions({
            analysts: Object.assign(
              ...[intl.formatMessage({ id: 'AnalystNotAssignedLabel' }), ...response.data.allAnalysts.sort()].map(key => ({ [key]: key })),
            ),
            companies: Object.assign(
              { 'Select...': intl.formatMessage({ id: 'SelectWithOptionLabel' }) },
              ...[...response.data.allCompanies]
                .sort((x, y) => x.toLocaleLowerCase(intl.locale)
                  .localeCompare(y.toLocaleLowerCase(intl.locale)))
                .map(key => ({ [key]: key })),
            ),
            countries: Object.assign(
              response.data.allCountries,
            ),
            regions: Object.assign(
              { 'Select...': intl.formatMessage({ id: 'SelectWithOptionLabel' }) },
              { MyRegion: intl.formatMessage({ id: 'MyRegionLabel' }) },
              response.data.allRegions,
            ),
          });
        }
      })
      .catch(error => error);
  }, [url]);

  function setPage(newPage) {
    setPaging({
      ...paging,
      page: newPage,
    });
  }

  function setColumnSort(category, value) {
    setSort({ category, value });
  }

  function setColumnFilter(category, value) {
    const newFilters = { ...filters };
    newFilters[category] = value;
    setFilters(newFilters);
    setPage(1);
  }

  function setColumnFilters(categoryValues) {
    const newFilters = { ...filters };
    Object.entries(categoryValues).forEach(([category, value]) => {
      newFilters[category] = value;
    });
    setFilters(newFilters);
    setPage(1);
  }

  function setMyRegionFilter(countryRegion, regionCategory) {
    const loginData = authService.getAuthInfo();
    const newFilters = { ...filters };
    newFilters[countryRegion] = null;
    newFilters[regionCategory] = loginData.profileModel.region;
    setFilters(newFilters);
    setPage(1);
  }

  return {
    setColumnSort,
    sort,
    setColumnFilter,
    filters,
    filteringOptions,
    pulseChecks,
    paging,
    setPage,
    setColumnFilters,
    setMyRegionFilter,
  };
}

export default usePulseCheckTable;

import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

class StaticSummary extends Component {
  constructor(props) {
    super(props);

    const t = this;
    t.state = {
      summaryData: null,
    };
  }

  render() {
    const t = this;
    const { intl } = t.props;
    return (
      <div className="Report-page-text Report-page-text-summary">
        {
          t.props.summaryData !== null
            ? (
              // eslint-disable-next-line react/no-danger
              <div className="Static-summary-div" dangerouslySetInnerHTML={{ __html: t.props.summaryData }} />
            )
            : <div />
        }
        <p>
          <FormattedMessage
            id="SupportMessage"
            values={{
              link: (
                <a href={`mailto:${intl.formatMessage({ id: 'SupportMessageEmail' })}`}>
                  <FormattedMessage id="SupportMessageEmail" />
                </a>
              ),
            }}
          />
        </p>
      </div>
    );
  }
}

export default injectIntl(StaticSummary);

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const BearingInformationTable = ({ assetData }) => (
  <table className="Page-two-table Bearing-table">
    <thead>
      <tr>
        <th colSpan="2" className="Table-title">
          <FormattedMessage id="BearingInformationLabel" />
        </th>
      </tr>
    </thead>
    <tbody>
      {assetData
        ? Object.entries(assetData.bearings).map(([key, value]) => (
          <tr key={key}>
            <td id="Bearing-table-item-title">
              <FormattedMessage id="BearingLabel" />
              {' '}
              {key}
            </td>
            <td className="Bearing-table-td">
              <table>
                <tbody>
                  <tr>
                    <td><FormattedMessage id="ManufacturerLabel" /></td>
                    <td>{value.manufacturer}</td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="DesignationLabel" /></td>
                    <td>{value.designation}</td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="InstallationDateLabel" /></td>
                    <td>
                      {(value.installationDate !== null) ? new Date(value.installationDate).toLocaleDateString() : ''}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        ))
        : (
          <tr>
            <td id="Bearing-table-item-title">
              <FormattedMessage id="BearingLabel" />
            </td>
            <td className="Bearing-table-td">
              <table>
                <tbody>
                  <tr>
                    <td><FormattedMessage id="ManufacturerLabel" /></td>
                    <td />
                  </tr>
                  <tr>
                    <td><FormattedMessage id="DesignationLabel" /></td>
                    <td />
                  </tr>
                  <tr>
                    <td><FormattedMessage id="InstallationDateLabel" /></td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        )}
    </tbody>
  </table>
);

BearingInformationTable.defaultProps = {
  assetData: {
    bearings: [
      {
        manufacturer: '',
        designation: '',
        installationDate: '',
      },
    ],
  },
};

BearingInformationTable.propTypes = {
  assetData: PropTypes.shape({
    bearings: PropTypes.objectOf(PropTypes.shape({
      manufacturer: PropTypes.string,
      designation: PropTypes.string,
      installationDate: PropTypes.string,
    })),
  }),
};

export default BearingInformationTable;

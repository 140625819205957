import axios from 'axios';
import * as jwt from 'jsonwebtoken';
import moment from 'moment';

import config from '../config';

class AuthService {
  constructor() {
    this.authInfoKey = 'AUTH_KEY';
  }

  saveAuthInfo(authInfo) {
    // eslint-disable-next-line no-undef
    localStorage.setItem(this.authInfoKey, JSON.stringify(authInfo));
  }

  getAuthInfo() {
    // eslint-disable-next-line no-undef
    const authInfo = localStorage.getItem(this.authInfoKey);
    return JSON.parse(authInfo);
  }

  static getAuthExpirationDateUtc(token) {
    const expiration = token.exp;
    return moment.unix(expiration);
  }

  isAuthValid = token => moment()
    .isBefore(AuthService.getAuthExpirationDateUtc(token));

  isAuthenticated() {
    const authInfo = this.getAuthInfo();

    if (!authInfo) {
      return false;
    }

    const token = jwt.decode(authInfo.accessToken);

    return (token && this.isAuthValid(token));
  }

  signIn = (userName, password) => {
    const loginUrl = new URL('api/profiles/login', config.API_URL);

    return axios
      .post(loginUrl, {
        userName,
        password,
        newPassword: '',
      });
  }

  signOut() {
    // eslint-disable-next-line no-undef
    localStorage.setItem(this.authInfoKey, null);
  }

  refreshToken() {
    const authInfo = this.getAuthInfo();
    if (!authInfo) return Promise.resolve();

    const { accessToken, refreshToken } = authInfo;
    const urlRefreshToken = new URL('api/profiles/refreshtoken', config.API_URL);
    return axios.post(urlRefreshToken, { accessToken, refreshToken })
      .then((refreshResult) => {
        // eslint-disable-next-line no-param-reassign
        if (!refreshResult.data.refreshToken) refreshResult.data.refreshToken = refreshToken;
        this.saveAuthInfo(refreshResult.data);
      });
  }
}

export default new AuthService();

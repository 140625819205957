import React, { Component } from 'react';
import Axios from 'axios';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import Alert from './Alerts';
import authService from '../../utils/auth-service';
import config from '../../config';

class CancelPulseCheck extends Component {
  constructor(props) {
    super(props);

    const t = this;

    this.toggle = this.toggle.bind(this);

    t.state = {
      status: t.props.status,
      pulseCheckId: t.props.pulseCheckId,
      closed: t.props.closed,
      cancelFailed: null,
      modal: false,
    };
  }

  componentDidMount() {
    const t = this;
    if (t.state.status === 'Cancelled') {
      t.props.onCancel(true);
    }
  }

  handleCancelBtnClick(pulseCheckId) {
    const pulseCheckLoginData = authService.getAuthInfo();
    const pulseCheckAssignUrl = `api/pulsechecks/${pulseCheckId}/Cancel`;
    const pulseCheckApiUrl = new URL(pulseCheckAssignUrl, config.API_URL);
    const t = this;

    return Axios.post(pulseCheckApiUrl, {
      CancellationReason: 'N/A',
    }, { headers: { Authorization: `Bearer ${pulseCheckLoginData.accessToken}` } })
      .then(() => {
        this.setState({
          status: 'Cancelled',
          cancelFailed: false,
        });
        t.props.onCancel(true);
        t.toggle();
      }).catch(() => {
        this.setState({
          cancelFailed: true,
        });
        t.props.onCancel(true);
        t.toggle();
      });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const {
      pulseCheckId,
      status,
      cancelFailed,
      modal,
    } = this.state;

    return (
      <>
        {
          (status !== 'Closed' && status !== 'Cancelled' && !cancelFailed)
            ? (
              <>
                <Button outline color="primary" onClick={this.toggle}>
                  <FormattedMessage id="CancelPulseCheckLabel" />
                </Button>
                <Modal isOpen={modal} toggle={this.toggle}>
                  <ModalHeader toggle={this.toggle}>
                    <FormattedMessage id="CancelPulseCheckLabel" />
                  </ModalHeader>
                  <ModalBody>
                    <FormattedMessage id="CancelPulseCheckQuestion" />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>
                      <FormattedMessage id="GoBackLabel" />
                    </Button>
                    <Button color="secondary" onClick={() => this.handleCancelBtnClick(pulseCheckId)}>
                      <FormattedMessage id="CancelPulseCheckLabel" />
                    </Button>
                  </ModalFooter>
                </Modal>
              </>
            )
            : (
              <Button disabled color="secondary">
                <FormattedMessage id="CancelPulseCheckLabel" />
              </Button>
            )
            }
        {
          // eslint-disable-next-line no-nested-ternary
          cancelFailed === null
            ? <div />
            : cancelFailed ? <Alert.CancelFailed /> : <Alert.CancelSucceed />
        }
      </>
    );
  }
}

export default CancelPulseCheck;

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
  Navbar,
  NavbarBrand,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { language } from 'react-icons-kit/fa/language';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LanguageCodes } from '../../utils/language-service';

const AuthenticatedNavItem = ({ onLogout, email, onSwitchLanguageTo }) => (
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav caret>
      {email}
    </DropdownToggle>
    <DropdownMenu right>
      <DropdownItem header><Icon icon={language} size="18" /></DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.Czech)}>Čeština</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.English)}>English</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.Spanish)}>Español</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.French)}>Français</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.Portugeuse)}>Português</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.Russian)}>Русский</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.Chinese)}>中文</DropdownItem>
      <DropdownItem divider />
      <DropdownItem onClick={onLogout}>
        <FormattedMessage id="LogoutLabel" />
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

AuthenticatedNavItem.defaultProps = {
  email: '',
};

AuthenticatedNavItem.propTypes = {
  onLogout: PropTypes.func.isRequired,
  email: PropTypes.string,
  onSwitchLanguageTo: PropTypes.func.isRequired,
};

const AnonymousNavItem = ({ onSwitchLanguageTo }) => (
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav caret>
      <Icon icon={language} size="18" />
    </DropdownToggle>
    <DropdownMenu right>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.Czech)}>Čeština</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.English)}>English</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.Spanish)}>Español</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.French)}>Français</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.Portugeuse)}>Português</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.Russian)}>Русский</DropdownItem>
      <DropdownItem onClick={() => onSwitchLanguageTo(LanguageCodes.Chinese)}>中文</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

AnonymousNavItem.propTypes = {
  onSwitchLanguageTo: PropTypes.func.isRequired,
};

const MainNavbar = ({
  isAuthenticated, onLogoutSuccess, email, onSwitchLanguageTo, region,
}) => (
  <Navbar className="navbar navbar-dark bg-primary">
    <NavbarBrand href={region} className="mr-auto">
      <FormattedMessage id="application_name" />
    </NavbarBrand>
    <Nav>
      {
        isAuthenticated
          ? (
            <AuthenticatedNavItem
              onLogout={onLogoutSuccess}
              email={email}
              onSwitchLanguageTo={onSwitchLanguageTo}
            />)
          : (
            <AnonymousNavItem
              onSwitchLanguageTo={onSwitchLanguageTo}
            />)
      }
    </Nav>
  </Navbar>
);

MainNavbar.defaultProps = {
  email: '',
  region: '',
};

MainNavbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  onLogoutSuccess: PropTypes.func.isRequired,
  email: PropTypes.string,
  onSwitchLanguageTo: PropTypes.func.isRequired,
  region: PropTypes.string,
};

export default withRouter(MainNavbar);

MainNavbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  onLogoutSuccess: PropTypes.func.isRequired,
  email: PropTypes.string,
  onSwitchLanguageTo: PropTypes.func.isRequired,
  region: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const PreviousPulseChecksTable = ({ previousPulseCheckTableData, history }) => (
  <div>
    {previousPulseCheckTableData != null
      ? (
        <table className="Page-two-table Previous-pulse-check-table">
          <thead>
            <tr>
              <th colSpan="5"><FormattedMessage id="AssetPulseChecks" /></th>
            </tr>
            <tr>
              <th className="Table-title"><FormattedMessage id="PulseCheckIdLabel" /></th>
              <th className="Table-title"><FormattedMessage id="StatusLabel" /></th>
              <th className="Table-title"><FormattedMessage id="AnalystLabel" /></th>
              <th className="Table-title"><FormattedMessage id="CompletionDateLabel" /></th>
              <th className="Table-title"><FormattedMessage id="FindingLabel" /></th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(previousPulseCheckTableData).map(([key, value]) => (
              (
                <tr key={key}>
                  <td>
                    <a href={`/pulseCheckData/${value.id}`} target="_blank" rel="noopener noreferrer">
                      <Button
                        className="Previous-pulse-check-id-button"
                        color="link"
                        disabled={value.id.toString() === history.location.pathname.replace('/pulseCheckData/', '')}
                      >
                        {value.id}
                      </Button>
                    </a>
                  </td>
                  <td><FormattedMessage id={`PulseCheckStatus${value.finalStatus}`} /></td>
                  <td>{value.analystName}</td>
                  <td>
                    {value.completedDate
                      ? `${moment(value.completedDate).format('HH:mm')}  ${moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr()} ${moment(value.completedDate).format('YYYY-MM-DD')}`
                      : '' }
                  </td>
                  <td><FormattedMessage id={`${value.worstRecommendationColor}Label`} /></td>
                </tr>
              )
            ))
          }
          </tbody>
        </table>
      )
      : (
        <tbody>
          <thead>
            <tr>
              <th className="Table-title"><FormattedMessage id="PulseCheckIdLable" /></th>
              <th className="Table-title"><FormattedMessage id="StatusLabel" /></th>
              <th className="Table-title"><FormattedMessage id="AnalystLabel" /></th>
              <th className="Table-title"><FormattedMessage id="CompletionDateLabel" /></th>
              <th className="Table-title"><FormattedMessage id="FindingLabel" /></th>
            </tr>
          </thead>
        </tbody>
      )
    }
  </div>
);

PreviousPulseChecksTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  previousPulseCheckTableData: PropTypes.any.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.any.isRequired,
  }).isRequired,
};

export default PreviousPulseChecksTable;

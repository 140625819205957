import React from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import moment from 'moment-timezone';
import { FormattedMessage, useIntl } from 'react-intl';

import usePulseCheckTable from './UsePulseCheckTable';
import StaticStrings from '../StaticStrings';
import Sorting from './Sorting';
import Filtering from './Filtering';
import Pagination from './Pagination';

function CreatePulseCheckTable() {
  const history = useHistory();
  const intl = useIntl();
  const {
    setColumnSort, sort, setColumnFilter, filters, filteringOptions, pulseChecks, paging, setPage,
    setColumnFilters, setMyRegionFilter,
  } = usePulseCheckTable();
  const formatTime = rawTime => (
    <span>
      <nobr>
        {moment(rawTime).format('HH:mm')}
        {moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr()}
      </nobr>
      <br />
      <nobr>
        {moment(rawTime).format('YYYY-MM-DD')}
      </nobr>
    </span>);
  const formatIndustry = rawName => (rawName == null ? rawName : rawName.replace(/\s/g, '').replace('&', 'And'));
  const formatApplication = rawName => rawName.replace(/\s/g, '').replace('&', 'And').replace('/', 'Or');
  const formatAssetType = rawName => rawName.replace(/\s/g, '').replace('&', 'And').replace('/', 'Or');

  const sortObject = (unsortedList) => {
    const sortedList = {};
    sortedList.Select = intl.formatMessage({ id: 'SelectWithOptionLabel' });

    const sortedEntries = Object.entries(unsortedList)
      .sort((a, b) => a[1].localeCompare(b[1]));

    sortedEntries.forEach((item) => {
      const key = item[0];
      const value = item[1];
      sortedList[key] = value;
    });
    return sortedList;
  };

  function RenderTable(pulseCheckList) {
    let countryRegionFilter;
    if (filters.region !== null) {
      countryRegionFilter = filteringOptions.regions[filters.region];
    } else if (filters.country !== null) {
      countryRegionFilter = filteringOptions.countries[filters.country];
    } else {
      countryRegionFilter = intl.formatMessage({ id: 'SelectWithOptionLabel' });
    }

    return (
      <div className="Pulse-check-table-div">
        <Table hover bordered>
          <thead>
            <tr>
              <th className="Id-column">
                <Sorting onSortChange={newStatus => setColumnSort('Id', newStatus)} status={sort.category === 'Id' ? sort.value : null}>
                  <FormattedMessage id="PulseCheckIdLabel" />
                </Sorting>
              </th>
              <th className="Status-column">
                <Sorting onSortChange={newStatus => setColumnSort('Status', newStatus)} status={sort.category === 'Status' ? sort.value : null}>
                  <FormattedMessage id="StatusLabel" />
                </Sorting>
                <Filtering
                  category="status"
                  items={sortObject(StaticStrings.getPulseCheckStatus(intl))}
                  selected={filters.status !== null ? intl.formatMessage({ id: `PulseCheckStatus${filters.status}` }) : intl.formatMessage({ id: 'SelectWithOptionLabel' })}
                  setColumnFilter={setColumnFilter}
                  caretDirection="down"
                  right={false}
                />
              </th>
              <th className="Analyst-column">
                <Sorting onSortChange={newStatus => setColumnSort('Analyst.LastName', newStatus)} status={sort.category === 'Analyst.LastName' ? sort.value : null}>
                  <FormattedMessage id="AnalystLabel" />
                </Sorting>
                <Filtering
                  category="analyst"
                  items={sortObject(filteringOptions.analysts)}
                  selected={filters.analyst !== null ? filters.analyst : intl.formatMessage({ id: 'SelectWithOptionLabel' })}
                  setColumnFilter={setColumnFilter}
                  caretDirection="down"
                  right={false}
                />
              </th>
              <th className="Required-date-column">
                <Sorting onSortChange={newStatus => setColumnSort('RequiredDate', newStatus)} status={sort.category === 'RequiredDate' ? sort.value : null}>
                  <FormattedMessage id="RequiredDateLabel" />
                </Sorting>
              </th>
              <th className="Completed-date-column">
                <Sorting onSortChange={newStatus => setColumnSort('CompletedDate', newStatus)} status={sort.category === 'CompletedDate' ? sort.value : null}>
                  <FormattedMessage id="CompletionDateLabel" />
                </Sorting>
              </th>
              <th className="Company-column">
                <FormattedMessage id="CompanyLabel" />
                <Filtering
                  category="company"
                  items={filteringOptions.companies}
                  selected={filters.company !== null ? filters.company : intl.formatMessage({ id: 'SelectWithOptionLabel' })}
                  setColumnFilter={setColumnFilter}
                  caretDirection="down"
                  right={false}
                />
              </th>
              <th className="Industry-column">
                <FormattedMessage id="IndustryLabel" />
                <Filtering
                  category="industry"
                  items={sortObject(StaticStrings.getIndustries(intl))}
                  selected={filters.industry !== null ? intl.formatMessage({ id: `Industry-${formatIndustry(filters.industry)}` }) : intl.formatMessage({ id: 'SelectWithOptionLabel' })}
                  setColumnFilter={setColumnFilter}
                  caretDirection="down"
                  right={false}
                />
              </th>
              <th className="Application-column">
                <FormattedMessage id="ApplicationLabel" />
                <Filtering
                  category="application"
                  items={sortObject(StaticStrings.getApplications(intl))}
                  selected={filters.application !== null ? intl.formatMessage({ id: `Application-${formatApplication(filters.application)}` }) : intl.formatMessage({ id: 'SelectWithOptionLabel' })}
                  setColumnFilter={setColumnFilter}
                  caretDirection="down"
                  right={false}
                />
              </th>
              <th className="Asset-type-column"><FormattedMessage id="AssetTypeColumnLabel" /></th>
              <th className="Asset-name-column"><FormattedMessage id="AssetNameColumnLabel" /></th>
              <th className="Asset-tag-column"><FormattedMessage id="AssetTagColumnLabel" /></th>
              <th className="Previous-pulse-check-column">
                <FormattedMessage id="PreviousPulseChecksColumnLabel" />
              </th>
              <th className="Country-column">
                <Sorting onSortChange={newStatus => setColumnSort('Requester.Country', newStatus)} status={sort.category === 'Requester.Country' ? sort.value : null}>
                  <FormattedMessage id="CountryLabel" />
                </Sorting>
                <Filtering
                  category="country"
                  items={filteringOptions.countries}
                  selected={countryRegionFilter}
                  setColumnFilter={setColumnFilter}
                  setColumnFilters={setColumnFilters}
                  setMyRegionFilter={setMyRegionFilter}
                  caretDirection="down"
                  right
                  itemsHeader={intl.formatMessage({ id: 'CountriesLabel' })}
                  extraItemsCategory="region"
                  extraItemsHeader={intl.formatMessage({ id: 'RegionsLabel' })}
                  extraItems={filteringOptions.regions}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {pulseCheckList !== [] ? pulseCheckList.map(pc => (
              <tr key={pc.id} onClick={() => history.push(`pulseCheckData/${pc.id}`)}>
                <td className="Id-column">{pc.id}</td>
                <td className="Status-column"><FormattedMessage id={`PulseCheckStatus${pc.status}`} /></td>
                <td>{pc.analyst ? `${pc.analyst.firstName} ${pc.analyst.lastName}` : <FormattedMessage id="AnalystNotAssignedLabel" />}</td>
                <td>{formatTime(pc.requiredDate)}</td>
                <td>{pc.completedDate ? formatTime(pc.completedDate) : <FormattedMessage id={`PulseCheckStatus${pc.status}`} />}</td>
                <td>{pc.requester.company}</td>
                <td>{pc.asset.industry !== '' && pc.asset.industry !== null ? <FormattedMessage id={`Industry-${formatIndustry(pc.asset.industry)}`} /> : <FormattedMessage id="Industry-Other" />}</td>
                <td>{pc.asset.application !== '' ? <FormattedMessage id={`Application-${formatApplication(pc.asset.application)}`} /> : ''}</td>
                <td>{pc.asset.assetType !== '' ? <FormattedMessage id={`AssetType-${formatAssetType(pc.asset.assetType)}`} /> : ''}</td>
                <td>{pc.asset.assetName}</td>
                <td>{pc.asset.assetTag}</td>
                <td>{pc.previousPulseCheckCount}</td>
                <td>{pc.requester.country}</td>
              </tr>
            )) : ''}
          </tbody>
        </Table>
        <div className="Pagination-buttons">
          <Filtering
            category="count"
            items={{
              10: '10',
              20: '20',
              30: '30',
              40: '40',
              50: '50',
            }}
            selected={paging.sizePerPage.toString()}
            caretDirection="up"
            setColumnFilter={setColumnFilter}
            right={false}
          />
          <Pagination paging={paging} setPage={setPage} />
        </div>
      </div>
    );
  }

  return (
    <div>
      {RenderTable(pulseChecks)}
    </div>
  );
}

const PulseCheckTable = () => (
  <CreatePulseCheckTable />
);

export default PulseCheckTable;

import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Value } from 'slate';
import { FormattedMessage } from 'react-intl';

import AssetStatus from './AssetStatus';
import ReportSummary from './ReportSummary';
import authService from '../../utils/auth-service';
import config from '../../config';
import Alert from './Alerts';

class AnalystPulseCheckEditor extends Component {
  constructor(props) {
    super(props);

    const t = this;
    t.state = {
      reportSummary: {
        summaryValue: t.props.pulseCheckData.pulseCheckSummary == null ? '' : t.props.pulseCheckData.pulseCheckSummary,
        value: Value.fromJSON(t.props.pulseCheckData.pulseCheckSummaryJson),
      },
      assetStatuses: null,
      saveFailed: false,
      summarySaveFailed: false,
      previousStatuses: t.props.statusData.assetRecommendations,
      closed: t.props.closed,
      cancelled: t.props.cancelled,
      pulseCheckId: t.props.pulseCheckData.id,
      canSaveSummary: t.props.pulseCheckData.pulseCheckSummary !== null ? true : null,
      isSaving: false,
    };
    this.onAssetStatusChange = this.onAssetStatusChange.bind(this);
    this.onReportSummaryChange = this.onReportSummaryChange.bind(this);
  }

  componentDidMount() {
    const t = this;
    if (t.props.pulseCheckData.pulseCheckSummary === null || t.state.assetStatuses !== null) {
      t.props.onCompletedPulseCheck(false);
    }
  }

  onAssetStatusChange(item) {
    const t = this;
    t.setState({ assetStatuses: item });
  }

  onReportSummaryChange(value, summaryValue, canSaveSummary) {
    const t = this;
    t.setState({
      reportSummary: { summaryValue, value },
      canSaveSummary,
    });
  }

  savePulseCheck() {
    const { props, state } = this;
    const {
      pulseCheckId, assetStatuses, reportSummary, canSaveSummary, saveFailed,
    } = state;
    const loginData = authService.getAuthInfo();
    const pulseCheckSummaryUrl = `api/pulsechecks/${pulseCheckId}/Summary`;
    const pulseCheckApiUrl = new URL(pulseCheckSummaryUrl, config.API_URL);
    const recommendationUrl = `api/pulsechecks/${pulseCheckId}/recommendations`;
    const recommendationApiUrl = new URL(recommendationUrl, config.API_URL);

    if (canSaveSummary === null || !canSaveSummary) {
      this.setState({ canSaveSummary: false });
    }
    if (reportSummary.summaryValue !== undefined && reportSummary.summaryValue.length > 8000) {
      this.setState({ summarySaveFailed: true });
      return;
    }

    this.setState({
      saveFailed: false,
      summarySaveFailed: false,
      isSaving: true,
    });

    setTimeout(() => {
      Axios
        .post(recommendationApiUrl, { assetStatuses },
          { headers: { Authorization: `Bearer ${loginData.accessToken}` } })
        .catch((error) => {
          const errorCode = error && error.response ? error.response.data.errorCode : undefined;
          this.setState({ summarySaveFailed: true, saveFailed: true, errorCode });
        }).then(() => {
          props.onCompletedPulseCheck(true);
          Axios.post(pulseCheckApiUrl,
            {
              pulseCheckSummary: reportSummary.summaryValue,
              pulseCheckSummaryJson: reportSummary.value,
            },
            { headers: { Authorization: `Bearer ${loginData.accessToken}` } });
        }).catch((error) => {
          props.onCompletedPulseCheck(false);
          if (!saveFailed) {
            const errorCode = error && error.response ? error.response.data.errorCode : undefined;
            this.setState({
              summarySaveFailed: true, saveFailed: true, isSaving: false, errorCode,
            });
          }
          return error;
        })
        .then(() => {
          this.setState({ isSaving: false });
          props.onCompletedPulseCheck(true);
        })
        .catch(() => props.onCompletedPulseCheck(false));
    }, 1000);
  }

  render() {
    const { props, state } = this;
    return (
      <div>
        <div className="row">
          <div className="Report-page-text-editor col-lg-6 col-sm-12">
            <div className="Report-page-title">
              <h4>
                <FormattedMessage id="SummaryLabel" />
              </h4>
            </div>
            <ReportSummary
              pulseCheckId={state.pulseCheckId}
              onReportSummaryChange={this.onReportSummaryChange}
              pulseCheckData={props.pulseCheckData}
              closed={props.closed}
              cancelled={props.cancelled}
              canEdit={props.canEdit}
            />
            {state.canSaveSummary === false
              ? <Alert.SummaryMissing />
              : <div className="Save-failed-div" /> }
            {state.summarySaveFailed
              ? <Alert.SummarySaveFailed />
              : <div className="Save-failed-div" /> }
          </div>
          <div className="col-lg-5 col-sm-12">
            <AssetStatus
              onAssetStatusChange={this.onAssetStatusChange}
              previousStatuses={state.previousStatuses}
              closed={props.closed}
              cancelled={props.cancelled}
              status={props.pulseCheckData.status}
              canEdit={props.canEdit}
            />
            {state.saveFailed
              ? <Alert.SaveFailed errorCode={state.errorCode} />
              : <div className="Save-failed-div" /> }
          </div>
        </div>
        <div className="row Save-report-btn">
          {state.saveFailed ? <div><Alert.SaveFailed /></div> : ''}
          <div className={state.isSaving ? 'Save-animation-div' : ''}>
            {
            // eslint-disable-next-line no-nested-ternary
            !props.canEdit
              ? <div />
              : state.closed || state.cancelled
                ? (
                  <Button color="secondary" disabled>
                    <FormattedMessage id="SaveReportSummaryLabel" />
                  </Button>)
                : (
                  <Button
                    className={state.isSaving ? 'save-animation Save-report-btn' : 'Save-report-btn'}
                    color="primary"
                    onClick={() => this.savePulseCheck()}
                  >
                    {!state.isSaving ? <FormattedMessage id="SaveReportSummaryLabel" /> : ''}
                  </Button>)
              }
          </div>
          {
            props.pulseCheckData && props.pulseCheckData.securePulseCheckId
              ? (
                <Link to={`/pulsecheckreport/${state.pulseCheckId}`}>
                  <Button color="primary">
                    <FormattedMessage id="ViewSummaryReportLabel" />
                  </Button>
                </Link>
              )
              : null
          }
        </div>
      </div>
    );
  }
}

export default AnalystPulseCheckEditor;

/* eslint-disable no-undef, no-alert, camelcase */
import React, { Component, Fragment } from 'react';
import { Editor } from 'slate-react';
import { Value } from 'slate';
import Icon from 'react-icons-kit';
import { ic_format_bold } from 'react-icons-kit/md/ic_format_bold';
import { ic_format_italic } from 'react-icons-kit/md/ic_format_italic';
import { ic_format_underlined } from 'react-icons-kit/md/ic_format_underlined';
import { ic_insert_link } from 'react-icons-kit/md/ic_insert_link';
import { ic_format_list_bulleted } from 'react-icons-kit/md/ic_format_list_bulleted';
import { ic_format_list_numbered } from 'react-icons-kit/md/ic_format_list_numbered';

import FormatToolbar from './FormatToolbar';

function wrapLink(editor, href) {
  editor.wrapInline({
    type: 'link',
    data: { href },
  });

  editor.moveToEnd();
}

function unwrapLink(editor) {
  editor.unwrapInline('link');
}

const initialValue = Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            leaves: [
              {
                text: '',
              },
            ],
          },
        ],
      },
    ],
  },
});

class ReportSummary extends Component {
  constructor(props) {
    super(props);
    const t = this;
    t.state = {
      value: initialValue,
    };
  }

  componentDidMount() {
    const { pulseCheckData } = this.props;
    const t = this;
    t.state.value = pulseCheckData.pulseCheckSummaryJson.object == null
      ? initialValue : Value.fromJSON(pulseCheckData.pulseCheckSummaryJson);
    t.state.readOnly = t.props.closed || t.props.cancelled
      || pulseCheckData.status === 'Closed' || pulseCheckData.status === 'Cancelled' || !t.props.canEdit;
    t.state.canSaveSummary = pulseCheckData.pulseCheckSummary !== null;
  }

  componentDidUpdate(nextProps) {
    const t = this;
    if (nextProps !== t.props) {
      t.setState({
        readOnly: nextProps.closed || nextProps.cancelled || nextProps.status === 'Closed' || nextProps.status === 'Cancelled' || !nextProps.canEdit,
      });
    }
  }

  onChange = ({ value }) => {
    const t = this;
    t.setState({
      value,
      canSaveSummary: true,
    });
    t.setSummaryValue();
    t.props.onReportSummaryChange(t.state.value, t.state.summaryValue, t.state.canSaveSummary);
  }

  onKeyDown = (e, editor, next) => {
    if (!e.ctrlKey) { return next(); }

    e.preventDefault();

    switch (e.key) {
      case 'b': {
        editor.toggleMark('bold');
        return true;
      }
      case 'i': {
        editor.toggleMark('italic');
        return true;
      }
      case 'u': {
        editor.toggleMark('underline');
        return true;
      }
      case 'n': {
        editor.toggleMark('number-list');
        return true;
      }
      case 'l': {
        editor.toggleMark('bullet-list');
        return true;
      }
      default:
        return next();
    }
  }

  renderMark = (props, editor, next) => {
    switch (props.mark.type) {
      case 'bold':
        return <strong>{props.children}</strong>;
      case 'italic':
        return <em property="italic">{props.children}</em>;
      case 'underline':
        return <u {...props.attributes}>{props.children}</u>;
      case 'bulleted-list':
        return (
          <ul {...props.attributes}>
            <li>
              {props.children}
            </li>
          </ul>
        );
      case 'numbered-list':
        return (
          <ol {...props.attributes}>
            <li>
              {props.children}
            </li>
          </ol>
        );
      default:
        return next();
    }
  }

  renderNode = (props, editor, next) => {
    switch (props.node.type) {
      case 'bulleted-list':
        return (
          <ul {...props.attributes}>
            {props.children}
          </ul>
        );
      case 'list-item':
        return (
          <li>
            {props.children}
          </li>
        );
      case 'numbered-list':
        return (
          <ol {...props.attributes}>
            {props.children}
          </ol>
        );
      case 'link': {
        const { data } = props.node;
        const href = data.get('href');
        return (
          <a {...props.attributes} href={href}>
            {props.children}
          </a>
        );
      }
      default:
        return next();
    }
  }

  hasBlock = (type) => {
    const { value } = this.state;
    return value.blocks.some(node => node.type === type);
  }

  renderBlockButton = (type) => {
    if (['numbered-list', 'bulleted-list'].includes(type)) {
      // eslint-disable-next-line no-unused-vars
      let isActive = this.hasBlock(type);
      const { value: { document, blocks } } = this.state;

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key);
        isActive = this.hasBlock('list-item') && parent && parent.type === type;
      }
    }
  }

  hasLinks = () => {
    const { value } = this.state;
    return value.inlines.some(inline => inline.type === 'link');
  }

  onLinkClick = (e) => {
    e.preventDefault();

    const { editor, props } = this;
    const { value } = editor;
    const hasLinks = this.hasLinks();

    if (!props.closed && !props.cancelled) {
      if (hasLinks) {
        editor.command(unwrapLink);
      } else if (value.selection.isExpanded) {
        const href = window.prompt('Enter the URL of the link:');

        if (href === null) {
          return;
        }

        editor.command(wrapLink, href);
      } else {
        const href = window.prompt('Enter the URL of the link:');

        if (href === null) {
          return;
        }

        const text = window.prompt('Enter the text for the link:');

        if (text === null) {
          return;
        }

        editor
          .insertText(text)
          .moveFocusBackward(text.length)
          .command(wrapLink, href);
      }
    }
  }

  onBlockClick = (event, type) => {
    event.preventDefault();

    const { editor, props } = this;
    const { value } = editor;
    const { document } = value;

    if (!props.closed && !props.cancelled) {
      if (type !== 'bulleted-list' && type !== 'numbered-list') {
        const isActive = this.hasBlock(type);
        const isList = this.hasBlock('list-item');

        if (isList) {
          value
            .setBlocks(isActive ? 'paragraph' : type)
            .unwrapBlock('bulleted-list')
            .unwrapBlock('numbered-list');
        } else {
          editor.setBlocks(isActive ? 'paragraph' : type);
        }
      } else {
        const isList = this.hasBlock('list-item');
        const isType = value.blocks.some(block => (
          !!document.getClosest(block.key, parent => parent.type === type)));

        if (isList && isType) {
          editor
            .setBlocks('paragraph')
            .unwrapBlock('bulleted-list')
            .unwrapBlock('numbered-list');
        } else if (isList) {
          editor
            .unwrapBlock(
              type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list',
            )
            .wrapBlock(type);
        } else {
          editor.setBlocks('list-item').wrapBlock(type);
        }
      }
    }
  }

  onMarkClick = (e, type) => {
    e.preventDefault();
    this.editor.toggleMark(type);
  }

  // eslint-disable-next-line no-return-assign
  ref = editor => this.editor = editor;

  onKeyPress = (e) => {
    const t = this;
    t.setState({
      value: e.target.innerHTML,
    });
  }

  setSummaryValue = () => {
    const html = document.getElementsByClassName('text-editor');
    const t = this;
    t.setState({
      summaryValue: html[0] === undefined ? null : html[0].innerHTML,
    });
  }

  render() {
    const { value, readOnly } = this.state;
    return (
      <Fragment>
        <FormatToolbar>
          <div className="btn-group" role="group" aria-label="Formatting buttons">
            <button
              disabled={readOnly}
              type="button"
              onPointerDown={e => this.onMarkClick(e, 'bold')}
              className="tooltip-icon-button btn btn-secondary"
            >
              <Icon icon={ic_format_bold} />
            </button>
            <button
              disabled={readOnly}
              type="button"
              onPointerDown={e => this.onMarkClick(e, 'italic')}
              className="tooltip-icon-button btn btn-secondary"
            >
              <Icon icon={ic_format_italic} />
            </button>
            <button
              disabled={readOnly}
              type="button"
              onPointerDown={e => this.onMarkClick(e, 'underline')}
              className="tooltip-icon-button btn btn-secondary"
            >
              <Icon icon={ic_format_underlined} />
            </button>
            <button
              disabled={readOnly}
              type="button"
              onPointerDown={e => this.onLinkClick(e)}
              className="tooltip-icon-button btn btn-secondary"
            >
              <Icon icon={ic_insert_link} />
            </button>
            <button
              disabled={readOnly}
              onPointerDown={e => this.onBlockClick(e, 'bulleted-list')}
              className="tooltip-icon-button btn btn-secondary"
              type="button"
            >
              <Icon icon={ic_format_list_bulleted} />
            </button>
            <button
              disabled={readOnly}
              onPointerDown={e => this.onBlockClick(e, 'numbered-list')}
              className="tooltip-icon-button btn btn-secondary"
              type="button"
            >
              <Icon icon={ic_format_list_numbered} />
            </button>
          </div>
        </FormatToolbar>
        <Editor
          ref={this.ref}
          className="text-editor"
          value={value}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          renderMark={this.renderMark}
          renderNode={this.renderNode}
          readOnly={readOnly}
        />
      </Fragment>
    );
  }
}

export default ReportSummary;

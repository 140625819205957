import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { Button } from 'reactstrap';
/* eslint-disable camelcase */
import { arrows_slim_down } from 'react-icons-kit/linea/arrows_slim_down';
import { arrows_slim_up } from 'react-icons-kit/linea/arrows_slim_up';
/* eslint-enable camelcase */


function selectNewStatus(status) {
  const values = [null, 'asc', 'desc'];
  switch (status) {
    case values[0]:
      return values[1];
    case values[1]:
      return values[2];
    case values[2]:
      return values[1];
    default:
      return values[0];
  }
}

const Sorting = (props) => {
  const { status, onSortChange, children } = props;
  return (
    <Button className="Sorting-button" color="link" onClick={() => onSortChange(selectNewStatus(status))}>
      {/* eslint-disable camelcase */}
      <nobr>
        {children}
        {status === 'desc' && <Icon className="Sorting-icon" icon={arrows_slim_down} size="15" />}
        {status === 'asc' && <Icon className="Sorting-icon" icon={arrows_slim_up} size="15" />}
      </nobr>
      {/* eslint-enable camelcase */}
    </Button>
  );
};

Sorting.defaultProps = {
  status: 'asc',
  onSortChange: null,
  children: null,
};

Sorting.propTypes = {
  status: PropTypes.string,
  onSortChange: PropTypes.func,
  children: PropTypes.element,
};

export default Sorting;

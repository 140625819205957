import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { injectIntl } from 'react-intl';

import DownloadData from './downloadData';
import DownloadDataFailed from './downloadDataFailed';
import authService from '../../../utils/auth-service';
import config from '../../../config';
import { IntlPropType } from '../../../utils/language-service';

class DownloadDataContainer extends Component {
  constructor(props) {
    super(props);

    const { pulseCheckId, intl } = this.props;

    this.state = {
      pulseCheckId,
      downloadDataButtonText: intl.formatMessage({ id: 'DownloadDataLabel' }),
      downloadDataFailed: false,
      downloading: false,
    };

    this.handleDownloadDataButton = this.handleDownloadDataButton.bind(this);
  }

  buildGenerateDataApiUrl() {
    const { pulseCheckId } = this.state;
    return new URL(`api/PulseChecks/${pulseCheckId}/GenerateData`, config.API_URL);
  }

  buildDownloadDataApiUrl() {
    const { pulseCheckId } = this.state;
    return new URL(`api/PulseChecks/${pulseCheckId}/DownloadData`, config.API_URL);
  }

  downloadData(fileName, retry = 0) {
    const { intl } = this.props;
    const loginData = authService.getAuthInfo();
    const downloadDataApiUrl = this.buildDownloadDataApiUrl();
    Axios({
      url: downloadDataApiUrl,
      method: 'GET',
      headers: { Authorization: `Bearer ${loginData.accessToken}` },
      responseType: 'blob',
    }).then((response) => {
      // eslint-disable-next-line no-undef
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // eslint-disable-next-line no-undef
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', `${fileName}`);

      // eslint-disable-next-line no-undef
      document.body.appendChild(link);
      link.click();
      this.setState({
        downloading: false,
        downloadDataButtonText: intl.formatMessage({ id: 'DownloadDataLabel' }),
      });
    }).catch((err) => {
      if (err.response.status === 404 && retry < config.REPORT_RETRY_COUNT) {
        const retryCount = retry + 1;
        setTimeout(() => this.downloadData(fileName, retryCount),
          retryCount * config.REPORT_RETRY_WAIT_IN_MILLISECONDS);
        this.setState({
          downloadDataButtonText: intl.formatMessage({ id: 'DownloadingDataMessage' }),
        });
      } else {
        this.setState({
          downloading: false,
          downloadDataFailed: true,
          downloadDataButtonText: intl.formatMessage({ id: 'DownloadDataLabel' }),
        });
      }
    });
  }

  handleDownloadDataButton(event) {
    const { intl } = this.props;
    event.preventDefault();

    this.setState({
      downloading: true,
      downloadDataFailed: false,
      downloadDataButtonText: intl.formatMessage({ id: 'DownloadingDataMessage' }),
    });

    const loginData = authService.getAuthInfo();
    const generateDataApiUrl = this.buildGenerateDataApiUrl();

    Axios.post(generateDataApiUrl, {}, { headers: { Authorization: `Bearer ${loginData.accessToken}` } })
      .then((response) => {
        if (response.data !== null) {
          this.downloadData(response.data.fileName);
        } else {
          this.setState({
            downloading: false,
            downloadDataFailed: true,
            downloadDataButtonText: intl.formatMessage({ id: 'DownloadDataLabel' }),
          });
        }
      }).catch(() => {
        this.setState({
          downloading: false,
          downloadDataFailed: true,
          downloadDataButtonText: intl.formatMessage({ id: 'DownloadDataLabel' }),
        });
      });
  }

  render() {
    const { downloadDataFailed, downloadDataButtonText, downloading } = this.state;

    return (
      <>
        {
          downloadDataFailed
            ? <DownloadDataFailed />
            : null
        }
        <DownloadData
          handleDownloadDataButton={this.handleDownloadDataButton}
          downloadDataButtonText={downloadDataButtonText}
          downloading={downloading}
        />
      </>
    );
  }
}

DownloadDataContainer.propTypes = {
  pulseCheckId: PropTypes.number.isRequired,
  intl: IntlPropType.isRequired,
};

export default injectIntl(DownloadDataContainer);
